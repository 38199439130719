import * as fromActions from './candidate.actions';
import * as fromActionTypes from 'store/actionTypes/profile';

const initialState = {
  baseInfo: {
    loading: false,
    // id
    // avatar image
    // name
    // documents & links
    // ..
  },
  summary: {
    loading: false,
    // ..
  },
  assessments: {
    loading: false,
    // ..
  },
  roleFit: {
    loading: false,
    // ..
  },
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromActions.RESET: {
      return {
        ...state,
        baseInfo: { loading: false },
        assessments: { loading: false },
      };
    }
    case fromActions.GET_CANDIDATE: {
      const { showLoading } = action.payload;

      return {
        ...state,
        baseInfo: { ...state.baseInfo, loading: showLoading },
      };
    }
    case fromActions.GET_CANDIDATE_SUCCESS: {
      return {
        ...state,
        baseInfo: { loading: false, ...action.payload.data },
      };
    }
    case fromActions.GET_CANDIDATE_FAIL: {
      return {
        ...state,
        baseInfo: { loading: false, error: action.payload.error },
      };
    }
    case fromActions.RESET_CANDIDATE_ASSESSMENTS: {
      return {
        ...state,
        assessments: { loading: false },
      };
    }
    case fromActions.GET_CANDIDATE_ASSESSMENTS: {
      const { showLoading } = action.payload;

      return {
        ...state,
        assessments: { ...state.assessments, loading: showLoading },
      };
    }
    case fromActions.GET_CANDIDATE_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        assessments: { loading: false, results: action.payload.data },
      };
    }
    case fromActions.GET_CANDIDATE_ASSESSMENTS_FAIL: {
      return {
        ...state,
        assessments: { loading: false, error: action.payload.error },
      };
    }
    case fromActionTypes.GET_PROFILE_RESULT_FROM_REPORT_FULFILLED: {
      const { id, data = {} } = action.payload;
      const { results, peers } = data;

      const newResults = JSON.parse(JSON.stringify(state.assessments.results || []));
      const assessment = newResults.find(({ assessment: aId }) => aId === id);
      if (assessment) {
        assessment.reportContent = results;
        assessment.peers = peers;
      }

      return {
        ...state,
        assessments: {
          ...state.assessments,
          results: newResults,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
