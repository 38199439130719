// REACT, STYLE, STORIES & COMPONENT
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styles from './CandidateQuickView.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import AssessmentsResultsSkeleton from 'features/+teams/pages/TeamStaffing/AssessmentsResultsSkeleton';
import {
  CollapsibleNext,
  ImgCircle,
  Link,
  SidePanel,
  BigToggle, RoleMatchSummary,
} from 'ui/basic';

import {
  Big5Result,
  CompetenciesResult,
  NineLevelsResult,
  PotentialResult,
  RmpResult,
  SkillsResult,
  WorkPreferencesResult,
} from 'ui/molecules';

// UTILS
import { useTranslate } from 'utils/translator';
import { isValid } from 'utils/numbers';
import { getAssessmentRange, getRoleMatchSummaryLinks } from 'utils/roles';
import { REFERENCE_PROFILE_TYPES } from 'utils/configuration/const/reference-profile';
import { ASSESSMENT_TITLES, ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { scrollIntoView } from 'utils/scrolling';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import * as api from 'api';
import * as actions from 'store/actions';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { convertCandidateStartDateToLocale } from 'utils/dateTools';
import * as fromActions from 'features/+candidates/store/candidate/candidate.actions';
import * as fromSelectors from 'features/+candidates/store/candidate/candidate.selectors';

// CONFIG & DATA
// const Config = {};
const CANDIDATE_INFO = [
  {
    value: 'mail',
    labelFallback: 'E-Mail',
  },
  {
    value: 'yearOfBirth',
    labelKey: 'year_of_birth',
    labelFallback: 'Alter',
    lookInProfileData: true,
  },
  {
    value: 'gender',
    labelKey: 'rmp_ass_form_gender_label',
    labelFallback: 'Geschlecht',
    lookInProfileData: true,
  },
  {
    value: 'residence',
    labelKey: 'residence_and_nationality',
    labelFallback: 'Wohnhaft in / Nationalität',
    lookInProfileData: true,
  },
  {
    value: 'education',
    labelKey: 'highest_school_degree',
    labelFallback: 'Höchster Schulabschluss',
    lookInProfileData: true,
  },
  {
    value: 'careerLevel',
    labelKey: 'current_career_level',
    labelFallback: 'Akt. Karrierestufe',
    lookInProfileData: true,
  },
  {
    value: 'positionAndJobFamily',
    labelKey: 'position_and_jobFamily',
    labelFallback: 'Aktuelle Position / Job-Familie',
    lookInProfileData: true,
  },
  {
    value: 'industryExperienceMapped',
    labelKey: 'industry_experience',
    labelFallback: 'Branchenerfahrung',
    lookInProfileData: true,
  },
  {
    value: 'motivation',
    labelKey: 'motivation',
    labelFallback: 'Motivation',
    lookInProfileData: true,
  },
  {
    value: 'jobRequirements',
    labelKey: 'job_requirements',
    labelFallback: 'Job-Anforderungen',
    lookInProfileData: true,
  },
  {
    value: 'earliestStart',
    labelKey: 'start_termin',
    labelFallback: 'Start-Termin',
    lookInProfileData: true,
  },
];

// COMPONENT: CandidateQuickView
const CandidateQuickView = (props) => {
  // PROPS
  const {
    token,
    candidate,
    vacancyId,
    role,
    showToggle,
    onClose,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const contentRef = useRef();

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessmentsWithNoClifton);

  const [ currentPage, setCurrentPage ] = useState('assessments');

  // CANDIDATE ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const candidateAssessmentsResultsFromStore = useSelector(fromSelectors.selectCandidateAssessments);
  const candidateAssessmentsResults = candidateAssessmentsResultsFromStore?.results;
  const candidateAssessmentsResultsLoading = candidateAssessmentsResultsFromStore?.loading;
  const candidateAssessments = candidateAssessmentsResults
  ?.filter((assessment) => assessment.result)
  ?.map((assessment) => {
    const thisAssessmentFromStore = assessmentsFromStore.find((a) => a.id === assessment.assessment);
    return {
      ...assessment,
      title: thisAssessmentFromStore?.title,
    };
  });

  useEffect(() => {
    if (!candidate) {
      return undefined;
    }

    dispatch(fromActions.getCandidateAssessments({ id: candidate.id }));
    return () => {
      dispatch(fromActions.resetCandidateAssessments());
    };
  }, [ dispatch, candidate ]);

  // CANDIDATE MATCHING: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ candidateMatching, setCandidateMatching ] = useState();
  const [ candidateMatchingLoading, setCandidateMatchingLoading ] = useState();

  const [ roleReferenceProfile, setRoleReferenceProfile ] = useState();
  const [ roleReferenceProfileLoading, setRoleReferenceProfileLoading ] = useState();

  const getMatchTitle = useCallback((score) => {
    if (score >= 90) {
      return translate(
        'employee_role_very_high_matching',
        [ '{{username}}', candidate.name, '{{role}}', (role.name) ],
      );
    }
    if (score < 90 && score >= 80) {
      return translate(
        'employee_role_high_matching',
        [ '{{username}}', candidate.name, '{{role}}', (role.name) ],
      );
    }
    if (score < 80 && score >= 40) {
      return translate(
        'employee_role_medium_matching',
        [ '{{username}}', candidate.name, '{{role}}', (role.name) ],
      );
    }
    return translate(
      'employee_matching_low',
      [ '{{username}}', candidate.name, '{{role}}', (role.name) ],
    );
  }, [ candidate, role, translate ]);

  useEffect(() => {
    if (currentPage === 'assessments' || (candidateAssessments && candidateAssessments.length > 0)) {
      return;
    }
    setRoleReferenceProfileLoading(true);

    const headerItems = {};
    if (token) {
      headerItems.Authorization = `Bearer ${token}`;
    }

    api.get(`rolemapping/roles/${role.id}`, { expand: 'referenceProfile' }, headerItems)
    .then(({ ok, status, data }) => {
      setRoleReferenceProfileLoading(false);
      const fetchedRole = { ...data };

      if (ok && status === 200) {
        // for manual roles profile values will be stored in 'generated property'
        // (for consistency with auto roles)
        if (fetchedRole.referenceProfile.type === REFERENCE_PROFILE_TYPES.MANUAL) {
          const profile = [ ...fetchedRole.referenceProfile.profile ];
          fetchedRole.referenceProfile.profile = { generated: profile };
        }

        setRoleReferenceProfile(fetchedRole.referenceProfile);
      }
    });
  }, [ currentPage, candidate.id, getMatchTitle, role, candidateAssessments, token ]);

  const handleShowRoleFit = () => {
    setCandidateMatchingLoading(true);

    const headerItems = {};
    if (token) {
      headerItems.Authorization = `Bearer ${token}`;
    }

    api.get(`/recruiting/jobs/${vacancyId}/matches`, { expand: 'score' }, headerItems)
    .then(({ ok, status, data }) => {
      setCandidateMatchingLoading(false);
      if (ok && status === 200) {
        const matchData = data.matches.find((match) => match.user === candidate.id);
        if (matchData) {
          setCandidateMatching({
            ...matchData,
            matchingText: getMatchTitle(matchData.score),
          });
        }
      }
    })
    .catch((error) => console.error(error.message));

    setRoleReferenceProfileLoading(true);
    const getRoleHeaderItems = {};
    if (token) {
      getRoleHeaderItems.Authorization = `Bearer ${token}`;
    }

    api.get(`rolemapping/roles/${role.id}`, { expand: 'referenceProfile' }, getRoleHeaderItems)
    .then(({ ok, status, data }) => {
      setRoleReferenceProfileLoading(false);
      const fetchedRole = { ...data };

      if (ok && status === 200) {
        // for manual roles profile values will be stored in 'generated property'
        // (for consistency with auto roles)
        if (fetchedRole.referenceProfile.type === REFERENCE_PROFILE_TYPES.MANUAL) {
          const profile = [ ...fetchedRole.referenceProfile.profile ];
          fetchedRole.referenceProfile.profile = { generated: profile };
        }

        setRoleReferenceProfile(fetchedRole.referenceProfile);
      }
    });
  };

  // ASSESSMENT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const storeAssessments = useSelector(fromAssessmentsSelectors.selectAssessmentsTable);
  const big5Assessment = storeAssessments[ASSESSMENT_TYPES.BIG5];
  const keyCompAssessment = storeAssessments[ASSESSMENT_TYPES.KEY_COMPETENCIES];
  const leadCompAssessment = storeAssessments[ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES];
  useEffect(() => {
    if (!big5Assessment) {
      dispatch(actions.getAssessment(ASSESSMENT_TYPES.BIG5));
    }

    if (!keyCompAssessment) {
      dispatch(actions.getAssessment(ASSESSMENT_TYPES.KEY_COMPETENCIES));
    }

    if (!leadCompAssessment) {
      dispatch(actions.getAssessment(ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES));
    }
  }, [ dispatch, big5Assessment, keyCompAssessment, leadCompAssessment ]);

  const getMappedAssessmentResults = (assessmentItem) => {
    let assessmentResults = [];
    let start = 0;
    let resultSchema = [];

    if (!roleReferenceProfile) {
      return assessmentResults;
    }

    let referenceProfile;
    if (roleReferenceProfile && roleReferenceProfile.profile) {
      const userProfile = roleReferenceProfile.profile;
      // manually created roles do not have current/generated values
      // it is always array of assessments stored right in 'profile' value
      if (Array.isArray(userProfile)) {
        referenceProfile = userProfile.find((p) => p.assessmentId === assessmentItem.assessment);
      } else if (userProfile.current && userProfile.current.length > 0) {
        // values in 'current' value have more priority than 'generated'
        referenceProfile = userProfile.current
        .find((p) => p.assessmentId === assessmentItem.assessment);
      } else if (userProfile.generated && userProfile.generated.length > 0 && !referenceProfile) {
        referenceProfile = userProfile.generated
        .find((p) => p.assessmentId === assessmentItem.assessment);
      }
    }

    switch (assessmentItem.assessment) {
      case ASSESSMENT_TYPES.BIG5:
        if (big5Assessment.resultSchema) {
          resultSchema = big5Assessment.resultSchema;
        }
        break;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        if (keyCompAssessment.resultSchema) {
          resultSchema = keyCompAssessment.resultSchema;
        }
        break;
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        if (leadCompAssessment.resultSchema) {
          resultSchema = leadCompAssessment.resultSchema;
        }
        break;
      default:
    }

    // filter out result values which are not valid (null, undefined, NaN)
    const assessmentValidResults = assessmentItem.result.results
    .filter((ar) => isValid(ar.result) || Array.isArray(ar.result));

    if (referenceProfile && referenceProfile.results.length
      && (assessmentValidResults.length > referenceProfile.results.length)
    ) {
      if (assessmentItem.assessment === ASSESSMENT_TYPES.RMP) {
        // romance is a dimension that is not used
        assessmentResults = assessmentValidResults.filter((assessmentResult) => assessmentResult.id !== 'romance');
      } else {
        resultSchema.forEach((rs) => {
          const subDimensionsLength = rs.subDimensions?.length ?? 1;
          assessmentResults.push(assessmentValidResults.slice(start, start + subDimensionsLength));
          // results includes subDimensions + dimensions
          // +1 because after sub dimensions values there is one more value for total result;
          start += subDimensionsLength + 1;
        });
        assessmentResults = assessmentResults.flatMap((res) => res);
      }
    } else {
      assessmentResults = [ ...assessmentValidResults ];
    }

    return assessmentResults.map((resultItem) => {
      let range;

      if (referenceProfile && referenceProfile.results) {
        const refProfileResult = referenceProfile.results.find((item) => {
          let { dimensionId } = item;
          if (assessmentItem.assessment === ASSESSMENT_TYPES.NINE_LEVELS && dimensionId.includes('cyan')) {
            dimensionId = dimensionId.replace('cyan', 'turquoise');
          }
          return dimensionId === resultItem.id;
        });

        if (refProfileResult) {
          range = getAssessmentRange(
            assessmentItem.assessment,
            refProfileResult.minValue,
            refProfileResult.maxValue,
          );
        }
      }

      return {
        id: resultItem.id,
        name: resultItem.name,
        result: resultItem.result,
        range,
      };
    });
  };

  const getResultComponent = ((assessmentType, assessmentResults, reportContent) => {
    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return <Big5Result results={assessmentResults} />;
      case ASSESSMENT_TYPES.POTENTIAL:
        return <PotentialResult results={assessmentResults} />;
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return <WorkPreferencesResult results={assessmentResults} />;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return (
          <CompetenciesResult
            assessmentType={assessmentType}
            results={assessmentResults}
          />
        );
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return (
          <CompetenciesResult
            assessmentType={assessmentType}
            results={assessmentResults}
          />
        );
      case ASSESSMENT_TYPES.RMP:
        return (
          <RmpResult results={assessmentResults} />
        );
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return (
          <NineLevelsResult results={assessmentResults} />
        );
      default:
        return (
          <SkillsResult
            assessmentType={assessmentType}
            userId={candidate?.id}
            results={assessmentResults}
            reportContent={reportContent}
          />
        );
    }
  });

  const getResultComponents = () => {
    const results = [];
    candidateAssessments.forEach((assessmentItem) => {
      const { assessment, result, reportContent } = assessmentItem;
      if (result?.results) {
        results.push(
          <div
            key={assessment}
            id={assessment}
          >
            { getResultComponent(assessment, result.results, reportContent) }
          </div>,
        );
      }
    });
    return results;
  };

  // RENDER: CandidateQuickView
  return (
    <SidePanel size='L' onClose={onClose}>
      <div className={styles.candidateQuickView}>
        { /* HEADER */ }
        <div className={styles.header}>
          <ImgCircle
            size='S'
            src={!candidate.isAnonymous ? api.getUserImageUrl(candidate.id) : undefined}
            label1={candidate.name.split(' ')[0]}
            label2={candidate.name.split(' ')[1]}
          />
          <div className={styles.rightBlock}>
            <div className={styles.name}>
              { candidate.name }
            </div>
            <div className={styles.position}>
              { candidate.profileData && candidate.profileData.currentPosition }
            </div>
          </div>
        </div>

        { /* CONTENT */ }
        <div className={styles.content} ref={contentRef}>
          { (candidate && candidate.profileCreated && !candidate.isAnonymous)
          && (
            <div className={styles.candidatesData}>
              <CollapsibleNext
                header={(
                  <span className={styles.label}>
                    { translate('candidate_info_lbl') }
                  </span>
                )}
                headerClassName={styles.header}
                withBorders
              >
                <>
                  { CANDIDATE_INFO.map((candidateInfo) => {
                    let value = candidateInfo.lookInProfileData
                      ? candidate.profileData[candidateInfo.value]
                      : candidate[candidateInfo.value];
                    value = convertCandidateStartDateToLocale(value);
                    if (Array.isArray(value)) {
                      value = value.filter(Boolean);
                      value = value.length
                        ? <ul>{ value.map((v) => <li key={v}>{ v }</li>) }</ul>
                        : null;
                    }
                    value = value || '-';

                    return (
                      <div className={styles.listItem} key={candidateInfo.value}>
                        <div className={styles.label}>
                          { candidateInfo.labelKey
                            ? (translate(candidateInfo.labelKey) || candidateInfo.labelFallback)
                            : candidateInfo.labelFallback }
                        </div>
                        <div className={styles.value}>
                          { value }
                        </div>
                      </div>
                    );
                  }) }
                </>
              </CollapsibleNext>
            </div>
          ) }

          { /* TOGGLE */ }
          { showToggle && (
            <div style={{ width: '288px' }}>
              <BigToggle
                options={[
                  { value: 'assessments', label: translate('candidate_assessments') },
                  { value: 'candidateRoleFit', label: translate('candidate_rolefit') },
                ]}
                onChange={(newIndex) => {
                  if (newIndex === 0) {
                    setCurrentPage('assessments');
                  } else {
                    setCurrentPage('role-fit');

                    if (!candidateMatching || !roleReferenceProfile) {
                      handleShowRoleFit();
                    }
                  }
                }}
              />
            </div>
          ) }

          { currentPage === 'assessments' && (
            <div className='marginTopXxs'>
              { candidateAssessmentsResultsLoading && <AssessmentsResultsSkeleton /> }

              { (!candidateAssessmentsResultsLoading && candidateAssessments?.length === 0) && (
                <div style={{ paddingTop: styles.spaceXs }}>
                  <div className='bluTypeXxs'>
                    { translate(
                      'employee_no_assessments',
                      [ '{{username}}', candidate.name ],
                    ) }
                  </div>
                  <div className={classNames('bluTypeCopyStrong', 'marginTopXs')}>
                    { translate('employee_no_assessments_descr') }
                  </div>
                </div>
              ) }

              { (!candidateAssessmentsResultsLoading && candidateAssessments?.length > 0) && (
                <>
                  <div className={styles.links}>
                    { candidateAssessments.map((assessmentItem) => (
                      <Link
                        key={assessmentItem.assessment}
                        type='anchor'
                        onClick={() => {
                          const thisAssessment = document.getElementById(assessmentItem.assessment);
                          if (thisAssessment) {
                            const { offsetTop } = thisAssessment;
                            contentRef.current.scrollTo({
                              top: offsetTop - 73 - 32, // 73 - fixed header height, 32 - top margin
                              behavior: 'smooth',
                            });
                          }
                        }}
                      >
                        { translate(ASSESSMENT_TITLES[assessmentItem.assessment])
                          || assessmentItem.title || assessmentItem.assessment }
                      </Link>
                    )) }
                  </div>

                  { /* ASSESSMENTS RESULTS */ }
                  <div className={styles.assessments}>
                    { getResultComponents() }
                  </div>
                </>
              ) }

            </div>
          ) }

          { currentPage === 'role-fit' && (
            <div className={styles.roleFit}>
              { (candidateMatchingLoading || roleReferenceProfileLoading) && (
                <AssessmentsResultsSkeleton />
              ) }

              { (!candidateMatchingLoading && !roleReferenceProfileLoading && candidateMatching) && (
                <>
                  <RoleMatchSummary
                    matchingText={candidateMatching && candidateMatching.matchingText}
                    matchingPercentage={candidateMatching && candidateMatching.score}
                    links={getRoleMatchSummaryLinks(translate, assessmentsFromStore, candidateMatching)}
                    onLinkClick={scrollIntoView}
                  />

                  { candidateAssessments && candidateAssessments.map((assessmentItem) => {
                    switch (assessmentItem.assessment) {
                      case ASSESSMENT_TYPES.BIG5:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <Big5Result
                              showReportButton={false}
                              results={getMappedAssessmentResults(assessmentItem)}
                            />
                          </div>
                        );
                      case ASSESSMENT_TYPES.POTENTIAL:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <PotentialResult
                              results={getMappedAssessmentResults(assessmentItem)}
                              showDimensionReport={false}
                            />
                          </div>
                        );
                      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <CompetenciesResult
                              assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
                              results={getMappedAssessmentResults(assessmentItem)}
                            />
                          </div>
                        );
                      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <CompetenciesResult
                              assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
                              results={getMappedAssessmentResults(assessmentItem)}
                            />
                          </div>
                        );
                      case ASSESSMENT_TYPES.WORK_PREFERENCES:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <WorkPreferencesResult
                              results={getMappedAssessmentResults(assessmentItem)}
                            />
                          </div>
                        );
                      case ASSESSMENT_TYPES.RMP:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <RmpResult
                              results={getMappedAssessmentResults(assessmentItem)}
                              showReportButton={false}
                            />
                          </div>
                        );
                      case ASSESSMENT_TYPES.NINE_LEVELS:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <NineLevelsResult
                              results={getMappedAssessmentResults(assessmentItem)}
                              showReportButton={false}
                            />
                          </div>
                        );
                      default:
                        return (
                          <div id={assessmentItem.assessment} key={assessmentItem.assessment}>
                            <SkillsResult
                              assessmentType={assessmentItem.assessment}
                              results={getMappedAssessmentResults(assessmentItem)}
                              showReportButton={false}
                            />
                          </div>
                        );
                    }
                  }) }

                </>
              ) }
            </div>
          ) }
        </div>

      </div>
    </SidePanel>
  );
};

export default CandidateQuickView;
