// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './RoleMatchSummary.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { Card, Link, ProgressCircle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: RoleMatchSummary
const RoleMatchSummary = (props) => {
  // PROPS
  const {
    children,
    matchingText,
    matchingPercentage = 0,
    links = [],
    showTopLabel = true,
    onLinkClick,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: RoleMatchSummary
  return (
    <Card>
      <div className={styles.roleMatchSummary}>
        <div className={styles.firstBlock}>
          <div className={styles.left}>
            { showTopLabel && (
              <div className={classNames('bluTypeLabel', styles.marginBottomXs)}>
                { translate('employee_role_fit') }
              </div>
            ) }

            <div className={classNames('bluTypeLabel', styles.matchingText)}>
              { matchingText }
            </div>

            { links
            .filter(({ id }) => id !== ASSESSMENT_TYPES.BALANCED_YOU) // Ignore BalancedYou
            .map((link) => {
              if (!link.clickable) {
                return (
                  <span
                    key={link.id}
                    className={styles.notLink}
                  >
                    { link.label }
                  </span>
                );
              }
              return (
                <Link
                  key={link.id}
                  type='anchor'
                  onClick={() => {
                    if (!link.clickable) {
                      return;
                    }
                    onLinkClick?.(link.id);
                  }}
                >
                  { link.label }
                </Link>
              );
            }) }
          </div>

          <div className={styles.progress}>
            <ProgressCircle
              progress={matchingPercentage}
              size='L'
              yellowDiff
              showProgress
            />
          </div>
        </div>

        <div className='marginTopS'>
          { children }
        </div>

      </div>
    </Card>
  );
};

export default RoleMatchSummary;
