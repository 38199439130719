// REACT, STYLE, STORIES & COMPONENT
import React, { forwardRef } from 'react';
import styles from './Chip.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import ImgCircle from 'ui/basic/micro-ui/ImgCircle';


// COMPONENT: Chip
const Chip = (props, ref) => {
  // PROPS
  const {
    children,
    onClick,
    img = '',
    showImage,
    label,
    look,
    showPlusIcon = false,
    showCloseIcon = true,
  } = props;

  // COMPONENT/UI STATE and REFS
  const hasCircle = showImage && (img || label);
  const labels = label && label.split(' ');


  // RENDER: Chip
  return (
    <div
      ref={ref}
      role='presentation'
      className={classNames(styles.chip, { [styles[look]]: look })}
      onClick={onClick}
    >
      { hasCircle && (
        <div className={styles.circleContainer}>
          <ImgCircle
            size='Ss'
            className='darker noBorder'
            label1={labels[0]}
            label2={labels[1]}
            src={img}
          />
        </div>
      ) }

      { showPlusIcon && (
        <div className={styles.svgContainer}>
          <IconsSvg.Plus />
        </div>
      ) }

      { children || label }

      { showCloseIcon && (
        <div className={styles.svgContainer}>
          <IconsSvg.CloseBig />
        </div>
      ) }
    </div>
  );
};

export default forwardRef(Chip);
