// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PeerGroupDescription.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// COMPONENTS
import { Chip } from 'ui/basic';

// UTILS
import { getUserImageUrl } from 'api';
import { translate } from 'utils/translator/translator';


// COMPONENT: PeerGroupDescription
const PeerGroupDescription = (props) => {
  // PROPS
  const {
    peerGroups,
    peers,
    content,
    configuration,
  } = props;


  // RENDER: ChipsSection
  const renderChipsSection = (descriptionKey, peersList) => {
    const anonymousPeers = peersList.reduce((acc, el) => acc + Number(!el.id), 0);

    if (!peersList.length) {
      return null;
    }

    return (
      <div className={styles.participantsGroup}>
        <div className={styles.participantsDescription}>
          { translate(descriptionKey) }
        </div>
        <div className={styles.participantsChips}>
          { peersList
          .filter((el) => el.id)
          .map((el) => (
            <Chip
              key={el.id}
              label={el.name}
              look='static'
              showCloseIcon={false}
              showImage
              img={getUserImageUrl(el.id)}
            />
          )) }
          { Boolean(anonymousPeers) && (
            <Chip
              look='static'
              showCloseIcon={false}
              showPlusIcon={anonymousPeers < peersList.length}
            >
              { translate(
                anonymousPeers > 1
                  ? 'custom_report_peer_group_descr_anonymous_plural'
                  : 'custom_report_peer_group_descr_anonymous_singular',
                [ '{{amount}}', anonymousPeers ],
              ) }
            </Chip>
          ) }
        </div>
      </div>
    );
  };

  // RENDER: Block
  const renderBlock = (key, label, description) => {
    const peersComplete = peers?.filter((el) => el.peerGroup === key && el.completed);
    const peersIncomplete = peers?.filter((el) => el.peerGroup === key && !el.completed);
    return (
      <div key={key} className={styles.peerGroupDescription}>
        <span className='bluTypeXxs'>{ label }</span>
        <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
          { description || '-' }
        </div>

        { Boolean(configuration?.showRespondents) && Boolean(peers?.length) && (
          <div className={styles.participants}>
            { renderChipsSection('custom_report_peer_group_descr_complete', peersComplete) }
            { renderChipsSection('custom_report_peer_group_descr_incomplete', peersIncomplete) }
            { !peersComplete.length && !peersIncomplete.length && (
              <div className={classNames(styles.participantsGroup, styles.noPeers)}>
                { translate('custom_report_peer_group_descr_no_peers') }
              </div>
            ) }
          </div>
        ) }
      </div>
    );
  };

  // RENDER: PeerGroupDescription
  return (content?.peerGroups ?? Object.keys(peerGroups))
  .filter((id) => peerGroups[id]?.label)
  .map((id) => renderBlock(id, peerGroups[id]?.label, peerGroups[id]?.description));
};

export default PeerGroupDescription;
