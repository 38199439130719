// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './Assessments.module.scss';

// ASSETS
import { ReactComponent as HumanSitting } from 'assets/keyvisuals/sitting-human.svg';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import {
  InfoCard,
  PillGroup,
  SkeletonFlexible,
  Card,
  Button,
} from 'ui/basic';
import {
  Big5Result,
  NineLevelsResult,
  PotentialResult,
  RmpResult,
  WorkPreferencesResult,
  NewSkillsResult,
} from 'ui/molecules';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import {

  ASSESSMENT_RECURRENCE_TYPES,
  ASSESSMENT_TYPES,
  ASSESSMENTS_INFO_CONTENT_KEYS,
  ASSESSMENTS_INFO_TITLE_KEYS,
  NINE_LEVELS_ORDER,
} from 'utils/configuration/const/assessment-types';

// STORE NEXT
import { useSelector } from 'react-redux';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { RunTable } from '../RecurringAssessments/RunTable';
import { IterationControl } from '../RecurringAssessments/IterationControl';
import { AssessmentHistory } from 'ui/molecules/AssessmentHistory';
import { useBreakpoint, useHeightAnimationStyle } from 'utils/hooks';
import { getTopParentAssessmentId } from 'utils/assessment';

// CONFIG & DATA
const Config = {
  RUN_FETCH_LIMIT: 10,
};


// COMPONENT: Assessments
const Assessments = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();
  const { isXs } = useBreakpoint();

  const assessments = useSelector(fromAssessmentsSelectors.selectAssessmentsBluquist);
  const parentAssessments = assessments.filter(({ parentId }) => !parentId);

  const assessmentsLoading = useSelector(fromAssessmentsSelectors.selectAssessmentsLoading);
  const [ iterationControlWrapperRef, iterationControlWrapperStyle ] = useHeightAnimationStyle();
  const [ resultsWrapperRef, resultsWrapperStyle ] = useHeightAnimationStyle();

  // EXPORT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ selectedAssessmentId, setSelectedAssessmentId ] = useState();
  const [ selectedRunId, setSelectedRunId ] = useState();
  const [ assessmentResults, setAssessmentResults ] = useState({});

  const selectedAssessment = assessments.find((a) => a.id === selectedAssessmentId);
  const selectedAssessmentResults = assessmentResults[selectedAssessmentId];
  const selectedRun = selectedAssessmentResults?.find(({ id }) => selectedRunId === id);
  const shouldStartRun = selectedAssessment?.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN
  && selectedAssessmentResults === null;

  const fetchAssessmentResults = async (assessmentId) => {
    let res;
    try {
      res = await api.get(`/core/assessments/${assessmentId}/result/company`);
    } catch (e) {
      console.error(e);
    } finally {
      let companyResults = res?.data?.results;
      if (!companyResults?.length) {
        companyResults = null;
      }
      setAssessmentResults((state) => ({ ...state, [assessmentId]: companyResults }));
    }
  };

  const fetchAssessmentRuns = async (assessmentId, offset = 0) => {
    let res;
    try {
      res = await api.get(
        `/core/assessments/${assessmentId}/run`,
        {
          include: 'results',
          limit: Config.RUN_FETCH_LIMIT,
          offset,
        },
      );
    } catch (e) {
      console.error(e);
    } finally {
      const runs = res?.data?.runs ?? [];

      setAssessmentResults((state) => {
        // Case empty fetch
        if (!runs.length) {
          return offset ? state : { ...state, [assessmentId]: null };
        }

        // Concatenate to already existing runs
        const prevRuns = state[assessmentId] ?? [];
        const newRuns = [ ...prevRuns.slice(0, offset), ...runs ];
        return ({ ...state, [assessmentId]: newRuns });
      });

      if (!offset) {
        setSelectedRunId(runs[0]?.id);
      }
    }
  };

  // Assessment setup
  useEffect(() => {
    if (!selectedAssessmentId && assessments.length > 0) {
      const route = new URLSearchParams(window.location.search).get('route');
      const index = Math.max(0, assessments.findIndex(({ id }) => id === route));
      setSelectedAssessmentId(assessments[index].id);
    }
  }, [ assessments, selectedAssessmentId ]);

  // Fetch assessment results
  useEffect(() => {
    if (!selectedAssessment) {
      return;
    }
    if (selectedAssessment.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN) {
      fetchAssessmentRuns(selectedAssessment.id);
    } else {
      fetchAssessmentResults(selectedAssessment.id);
    }

    // fetch results for follow-up assessments
    assessments.forEach((a) => {
      const parentAssessmentId = getTopParentAssessmentId(a.id, assessments);
      if (parentAssessmentId === selectedAssessment.id && parentAssessmentId !== a.id) {
        fetchAssessmentResults(a.id);
      }
    });
  }, [ selectedAssessment ]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load old runs
  useEffect(() => {
    const fetchedRuns = selectedAssessmentResults?.length ?? 0;
    const selectedRunIndex = selectedAssessmentResults?.findIndex(({ id }) => selectedRunId === id);
    if (selectedRunIndex > 0 && selectedRunIndex + 1 === fetchedRuns) {
      fetchAssessmentRuns(selectedAssessmentId, fetchedRuns);
    }
  }, [ selectedAssessmentId, selectedAssessmentResults, selectedRunId ]);

  const getResultsDiagram = () => (selectedAssessmentResults ?? [])
  .slice()
  .reverse()
  .map((run) => ({
    date: run.createdAt,
    results: (run.results?.results || [])
    .filter((dim) => !dim.parentDimension)
    .map((dim) => ({
      id: dim.id,
      name: dim.name,
      result: dim.result,
    })),
  }));


  // RENDER: result component
  const renderResultComponent = (assessmentId) => {
    let results = selectedAssessment.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN
      ? selectedRun?.results?.results
      : assessmentResults[assessmentId];

    const currentAssessment = assessments.find((a) => a.id === assessmentId);
    const running = selectedRunId && selectedAssessment.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN;
    if (!results) {
      return (
        <Card>
          <div className='bluTypeLabelL'>
            { currentAssessment.title }
          </div>
          <div className={styles.noData}>
            { running
              ? translate('assessments_results_no_run_data')
              : translate('assessments_results_no_data', [ '{{assessment}}', currentAssessment.title ]) }
          </div>
        </Card>
      );
    }

    results = results?.map((result) => ({
      ...result,
      range: [ result.firstQuartile, result.thirdQuartile ],
    }));

    switch (assessmentId) {
      case ASSESSMENT_TYPES.BIG5:
        return (
          <div id={assessmentId}>
            <Big5Result
              results={results}
              showInfoTitle={false}
              showReportButton={false}
            />
          </div>
        );
      case ASSESSMENT_TYPES.RMP:
        return (
          <div id={assessmentId}>
            <RmpResult
              results={results}
              showInfoTitle={false}
              showPendingState
            />
          </div>
        );
      case ASSESSMENT_TYPES.NINE_LEVELS: {
        results = Object.keys(NINE_LEVELS_ORDER)
        .map((color) => {
          const [ positive, negative ] = (results ?? [])
          .filter(({ id }) => id.includes(color))
          .sort((a, b) => a.id.length - b.id.length);

          if (!positive || !negative) {
            return null;
          }
          return {
            id: positive.id.replace('cyan', 'turquoise'), // translate cyan key
            name: positive.name,
            result: [
              -Math.abs(Math.ceil(negative.result)),
              Math.ceil(positive.result),
            ],
            range: positive.range, // resistance range is ignored
          };
        })
        .filter(Boolean);

        return (
          <div id={assessmentId}>
            <NineLevelsResult
              results={results}
              showInfoTitle={false}
              exportView
            />
          </div>
        );
      }
      case ASSESSMENT_TYPES.POTENTIAL:
        return (
          <div id={assessmentId}>
            <PotentialResult
              results={results}
              showInfoTitle={false}
            />
          </div>
        );
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return (
          <div id={assessmentId}>
            <WorkPreferencesResult
              results={results}
              showInfoTitle={false}
            />
          </div>
        );
      default: {
        return (
          <div id={assessmentId}>
            <NewSkillsResult
              assessmentData={currentAssessment}
              assessmentResults={results}
              showInfoTitle={false}
            />
          </div>
        );
      }
    }
  };

  const renderResultComponentWrapper = (assessmentId) => (
    <React.Fragment key={assessmentId}>
      { renderResultComponent(assessmentId) }
      { assessments
      .filter((a) => a.parentId === assessmentId)
      .map((a) => renderResultComponentWrapper(a.id)) }
    </React.Fragment>
  );

  if (!assessmentsLoading && assessments?.length === 0) {
    return (
      <div className={styles.assessments}>
        <span className='bluTypeXs'>{ translate('main_navigation_item_5') }</span>
        <div className={styles.noAssessments}>
          <span className='bluTypeXxs'>{ translate('my_org_no_assessments') }</span>
          <div className={classNames('bluTypeCopy', 'marginTopS')}>
            { translate('my_org_no_assessments_copy') }
          </div>
          <HumanSitting />
        </div>
      </div>
    );
  }

  if (assessmentsLoading || !selectedAssessmentId) {
    return (
      <div className={classNames(styles.assessments)}>
        <div className='bluTypeXs'>
          { translate('main_navigation_item_5') }
        </div>
        <div className={classNames(styles.loading)}>
          <SkeletonFlexible noHeader repeat={9} />
        </div>
      </div>
    );
  }

  // RENDER: Assessments
  return (
    <div className={classNames(styles.assessments)}>
      <span className='bluTypeXs'>
        { translate('main_navigation_item_5') }
      </span>

      <div className={styles.gridContainer}>
        <div className={styles.full}>
          { parentAssessments.length > 1 && (
            <PillGroup
              activeIndex={parentAssessments.findIndex((item) => item.id === selectedAssessmentId)}
              looks='primary'
              pillItems={parentAssessments.map(({ id, title }) => ({ id, label: title }))}
              wrap={!isXs}
              onPillClick={({ id }) => {
                if (id !== selectedAssessmentId) {
                  history.push(`/my-organization/assessments?route=${id}`);
                  setSelectedAssessmentId(id);
                  setSelectedRunId();
                  setAssessmentResults({});
                }
              }}
            />
          ) }
        </div>

        { shouldStartRun
          ? (
            <div className={classNames([ styles.full, styles.run ])}>
              <div className={classNames('bluTypeXs')}>
                { translate('iteration_control_run_start', [ '{{assessment}}', selectedAssessment.title ]) }
              </div>
              <div className={styles.description}>
                { translate('iteration_control_run_descr', [ '{{assessment}}', selectedAssessment.title ]) }
              </div>
              <div>
                <Button
                  size='S'
                  onClick={() => history.push(`/assessments/${selectedAssessmentId}/new-run`)}
                >
                  { translate('iteration_control_run_create') }
                </Button>
              </div>
              <div>
                <HumanSitting />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.left}>
                <div
                  className={styles.resultWrapper}
                  ref={resultsWrapperRef}
                  style={resultsWrapperStyle}
                >
                  <div className={styles.assessmentsResultsContainer}>
                    { renderResultComponentWrapper(selectedAssessment?.id) }
                  </div>
                </div>
                { selectedAssessment?.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN && (
                  <>
                    { Boolean(getResultsDiagram()?.length) && (
                      <AssessmentHistory
                        title='assmnt_history_org_title'
                        range={[
                          selectedAssessment.renderReferenceMin,
                          selectedAssessment.renderReferenceMax,
                        ]}
                        monthlyMode
                        results={getResultsDiagram()}
                        historyIndex={selectedAssessmentResults?.findIndex((el) => el.id === selectedRun?.id)}
                      />
                    ) }
                    <RunTable
                      entity={selectedAssessment}
                      selectedRun={selectedRun}
                      setSelectedRunId={setSelectedRunId}
                      runs={assessmentResults[selectedAssessmentId]}
                    />
                  </>
                ) }
              </div>

              <div className={classNames(styles.right)}>
                { selectedAssessment?.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN && (
                  <div
                    ref={iterationControlWrapperRef}
                    style={iterationControlWrapperStyle}
                  >
                    <IterationControl
                      selectedRun={selectedRun}
                      setSelectedRunId={setSelectedRunId}
                      runs={assessmentResults[selectedAssessmentId]}
                      entity={selectedAssessment}
                      onEdit={() => fetchAssessmentRuns(selectedAssessmentId)}
                    />
                  </div>
                ) }
                <InfoCard
                  title={translate(ASSESSMENTS_INFO_TITLE_KEYS[selectedAssessmentId]) || selectedAssessment?.title}
                  content={translate(ASSESSMENTS_INFO_CONTENT_KEYS[selectedAssessmentId])
                    || selectedAssessment?.description}
                />
              </div>
            </>
          ) }
      </div>
    </div>
  );
};

export default Assessments;
