// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useRef } from 'react';
import styles from './AttachmentsModalAdd.module.scss';
import { BigToggle, Button } from 'ui/basic';
import FormLink from './FormLink';
import FormDocument from './FormDocument';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: AttachmentsModalAdd
const AttachmentsModalAdd = (props) => {
  // PROPS
  const {
    onAttachmentAdd,
    vacancyMode = false,
    introKey = '',
  } = props;

  const inputFileRef = useRef();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLinkValid, setIsLinkValid ] = useState(false);
  const [ isLinkSaved, setIsLinkSaved ] = useState(false);
  const [ linkErrorMessage, setLinkErrorMessage ] = useState('');
  const [ toggle, setToggle ] = useState(0);

  const [ isDocumentValid, setIsDocumentValid ] = useState(false);
  const [ isDocumentSaved, setIsDocumentSaved ] = useState(false);
  const [ documentErrorMessage, setDocumentErrorMessage ] = useState();

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const onLinkValid = () => {
    setIsLinkValid(true);
  };

  const onLinkInvalid = () => {
    setIsLinkValid(false);
  };

  const linkSavedCallback = () => {
    setIsLoading(false);
    setLinkErrorMessage('');
    setIsLinkSaved(true);
  };

  const linkErrorCallback = (errorMessageParam) => {
    setIsLoading(false);
    setLinkErrorMessage(errorMessageParam || 'Error');
  };

  const onLinkSubmit = (link) => {
    setIsLoading(true);
    onAttachmentAdd({ ...link, type: 'link' }, linkSavedCallback, linkErrorCallback);
  };

  const onAddMoreLinkClick = () => {
    setIsLinkSaved(false);
  };

  const onFileInputResetClick = (e) => {
    e.preventDefault();
    inputFileRef.current.reset();
    setIsDocumentValid(false);
  };

  const onDocumentValid = () => {
    setIsDocumentValid(true);
  };

  const onDocumentInvalid = () => {
    setIsDocumentValid(false);
  };

  const documentSavedCallback = () => {
    setIsLoading(false);
    setDocumentErrorMessage('');
    setIsDocumentSaved(true);
  };

  const documentSavedErrorCallback = (errorMessageParam) => {
    setIsLoading(false);
    setDocumentErrorMessage(errorMessageParam || 'Error');
  };

  const onDocumentSubmit = (doc) => {
    setIsLoading(true);
    onAttachmentAdd({ ...doc, type: 'file' }, documentSavedCallback, documentSavedErrorCallback);
  };

  const onAddMoreDocumentClick = () => {
    setIsDocumentSaved(false);
    setIsDocumentValid(false);
  };

  // RENDER: Success
  const renderSuccess = (type, onAddMore) => (
    <div className={styles.success}>
      <h6>
        { '👍 ' }
        { translate(`candidate_attachments_add_${type}_added_header`) }
      </h6>
      <p>
        { translate(`candidate_attachments_add_${type}_added_text`) }
      </p>

      <Button
        looks='secondary'
        size='s'
        leadingIcon={IconsSvg.Plus}
        onClick={onAddMore}
      >
        { translate(`candidate_attachments_add_${type}_more`) }
      </Button>
    </div>
  );

  // RENDER: AttachmentsModalAdd
  return (
    <div className={styles.attachmentsModalAdd}>
      <h5>{ translate('candidate_attachments_add_header') }</h5>
      <p>
        { vacancyMode
          ? translate('vacancy_attachments_add_copy')
          : translate(introKey) }
      </p>

      <div className='marginBottomS'>
        <BigToggle
          activeIndex={toggle}
          options={[
            { value: 'attachmentsLink', label: translate('attachments_link') },
            { value: 'attachmentsDoc', label: translate('attachments_doc') },
          ]}
          onChange={(value) => {
            setToggle(value);
            setIsLinkValid(false);
            setIsDocumentValid(false);
          }}
        />
      </div>

      { toggle === 0 ? (
        <div>
          { isLinkSaved
            ? renderSuccess('link', onAddMoreLinkClick)
            : (
              <div className={styles.formLink}>
                <h6>
                  { translate('candidate_attachments_add_link') }
                </h6>

                { linkErrorMessage && (
                  <div className={styles.errorMessage}>
                    { linkErrorMessage }
                  </div>
                ) }

                <FormLink
                  vacancyMode={vacancyMode}
                  isLoading={isLoading}
                  onValid={onLinkValid}
                  onInvalid={onLinkInvalid}
                  onSubmit={onLinkSubmit}
                >
                  <div className={classNames(styles.linkAddButtonWrapper, styles.left)}>
                    <Button
                      looks='primary'
                      size='s'
                      type='submit'
                      disabled={!isLinkValid || isLoading}
                    >
                      { translate('candidate_attachments_add_link') }
                    </Button>
                  </div>
                </FormLink>
              </div>
            ) }
        </div>
      ) : (
        <div>
          { isDocumentSaved
            ? renderSuccess('doc', onAddMoreDocumentClick)
            : (
              <div>
                <h6>
                  { translate('candidate_attachments_add_doc_header') }
                </h6>

                <p>
                  { translate('candidate_attachments_add_doc_text') }
                </p>

                <FormDocument
                  vacancyMode={vacancyMode}
                  inputFileRef={inputFileRef}
                  disabled={isLoading}
                  onValid={onDocumentValid}
                  onInvalid={onDocumentInvalid}
                  onSubmit={onDocumentSubmit}
                >
                  { isDocumentValid === true && (
                    <>
                      { /* Error Message */ }
                      <div className={styles.errorMessage}>
                        { documentErrorMessage }
                      </div>

                      <div className={styles.documentAddButtonWrapper}>
                        <Button
                          looks='primary'
                          size='s'
                          type='submit'
                          disabled={isLoading}
                        >
                          { translate('candidate_attachments_add_doc_add') }
                        </Button>

                        <Button
                          looks='secondary'
                          size='s'
                          disabled={isLoading}
                          onClick={onFileInputResetClick}
                        >
                          { translate('candidate_attachments_add_doc_discard') }
                        </Button>
                      </div>
                    </>
                  ) }
                </FormDocument>
              </div>
            ) }
        </div>
      ) }
    </div>
  );
};

export default AttachmentsModalAdd;
