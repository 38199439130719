import React, { useState, useEffect } from 'react';
import styles from './BigToggle.module.scss';
import classNames from 'classnames';


const BigToggle = (props) => {
  const {
    activeIndex = 0,
    options = [],
    inlineStyling,
    onToggle, // deprecated
    onChange,
  } = props;

  const [ internalValue, setInternalValue ] = useState();

  useEffect(() => {
    setInternalValue(options[activeIndex]?.value);
  }, [ activeIndex ]); // eslint-disable-line react-hooks/exhaustive-deps
  // NOTE: options excluded from dependency array
  // because some components set options on every render

  return (
    <div className={classNames(styles.bigToggle, { [styles.inline]: inlineStyling })}>
      { /* BLUE BACKGROUND */ }
      <div className={classNames(
        styles.blue,
        { [styles.right]: options.findIndex(({ value }) => value === internalValue) === 1 },
      )}
      />

      { options.map((option, index) => (
        <div
          key={`option${index}`}
          className={classNames(
            styles.option,
            { [styles.active]: option.value === internalValue },
          )}
          onClick={() => {
            setInternalValue(option.value);

            onToggle?.(option.value);
            onChange?.(index);
          }}
        >
          { option.label }
        </div>
      )) }
    </div>
  );
};

export default BigToggle;
