// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './GapAnalysis.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { Callout, LineDiagram } from 'ui/basic';
import { setDecimalSeparator } from 'utils/userpreference';
import { sortAlphabetically } from 'utils/strings';

// CONFIG & DATA
const Config = {
  mode: {
    classes: 'classes', // default
    select: 'select',
    largest: 'largest',
    largestPositive: 'largest-positive',
    largestNegative: 'largest-negative',
    smallest: 'smallest',
    smallestPositive: 'smallest-positive',
    smallestNegative: 'smallest-negative',
    compare: 'compare',
    manual: 'manual',
  },
  classes: { // note: mind the plural forms
    clearStrength: 'clearStrengths',
    clearWeakness: 'clearWeaknesses',
    unrecognizedStrength: 'unrecognizedStrengths',
    unrecognizedWeakness: 'unrecognizedWeaknesses',
  },
};

// Refer to the specs here:
// https://blueexcellence.atlassian.net/browse/BQP-1021
// extended: https://blueexcellence.atlassian.net/browse/BDE-636

// COMPONENT: GapAnalysis
const GapAnalysis = (props) => {
  // PROPS
  const {
    configuration = {},
    content = {},
    segmentsCount,
    renderMin,
    assessmentResults = [],
    assessmentResultsAll = [],
    forCustomReport = false,
    replacements,
  } = props;

  const {
    // The sorting and charts mode, see Config above
    mode = Config.mode.classes,
    // (classes mode) If set to a positive integer value, only show at most this many dimensions for each category.
    // Sort by absolute size of gap and include only up to the top x dimensions.
    limitDimensionsPerClass,
    // (classes mode) If set to true, only consider top-level dimensions
    // and do not include sub-dimensions into the charts.
    hideEmptyClasses,
    // (classes mode) The selected dimensions depth
    dimensionLevel,
    // Determines whether the ranking numbers in front of the chart titles should be displayed.
    // Only relevant for mode settings where multiple charts are displayed.
    // For single charts the numbers should always be omitted (see mode).
    showNumbers: propsShowNumbers = true,
    // Determines whether title of the charts should be displayed.
    // Disabling this should also disable the numbers, even if showNumbers==true
    showTitle = true,
    // Only for modes largest* and smallest*. The number of dimensions to show. Defaults to 5.
    // This is comparable to what limitDimensionsPerClass does for mode=="classes".
    numResults = 5,
    // Allows changing the colors of the self and peer rating dots on the charts.
    // When changing othersColor the gap marker also changes the color to the corresponding light color.
    selfColor,
    othersColor,
  } = configuration;

  const {
    // Supported for all modes except manual and compare. Only consider sub-dimensions of this top-level-dimension.
    // Unless otherwise specified by the mode, the sub-dimensions should be ordered by their id.
    // Only one of 'dimensions' or 'subDimensionsOf' can be provided. If both are set, 'subDimensionsOf' wins.
    subDimensionsOf = null,
    // Supported for all modes except manual and compare. Only consider these provided dimensions.
    dimensions: propsDimensions = null,
    // Supported for all modes except classes, manual and compare.
    // Only compare with this peer group, instead of the overall peer result.
    peerGroup,
    // (compare mode) Provide the two dimensions that should be compared with each other:
    // selfDimension, otherDimension
    compare = {},
    // (manual mode) Set the minimum for chart to be displayed.
    rangeFrom,
    // (manual mode) Set the maximum for chart to be displayed.
    rangeTo,
    // (manual mode) Sets the self value of the chart.
    selfValue,
    // (manual mode) Sets the others value of the chart.
    othersValue,
    // Overrides the default title of the chart.
    title,
    // Overrides the default self label of the chart.
    selfLabel,
    // Overrides the default others label of the chart.
    othersLabel,
    // Overrides the default gap label of the chart.
    gapLabel,
  } = content;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const replacementsList = Object.entries(replacements ?? {})
  .map(([ key, value ]) => [ `{{${key}}}`, value ])
  .flat();

  // Find a dimension given its id
  const findDimension = (dimId) => {
    const allDimensions = [
      ...assessmentResultsAll,
      ...assessmentResultsAll.map((el) => el.subDimensions).filter(Array.isArray).flat(),
    ];
    return allDimensions.find(({ id }) => id === dimId);
  };

  // Get dimension ready to be processed by rendering fn
  const mapDimension = (dimension) => {
    const selfScore = dimension.peerResults.find((pr) => pr.id === 'self')?.result || 1;
    const others = dimension.peerResults.filter((pr) => (peerGroup ? pr.id === peerGroup : pr.id !== 'self'));
    const othersScore = others.reduce((acc, current) => acc + current.result, 0) / others.length;
    const gap = othersScore - selfScore;
    const name = title || dimension.name || dimension.id;
    return {
      id: dimension.id,
      name,
      selfScore,
      othersLabel: peerGroup ? others[0]?.name : null,
      othersScore,
      gap,
    };
  };

  const mapDimensionForClasses = (dimension) => {
    const selfScore = dimension.peerResults.find((pr) => pr.id === 'self')?.result || 1;
    const others = dimension.peerResults.filter((pr) => pr.id !== 'self');
    const othersScore = others.reduce((acc, current) => acc + current.result, 0) / others.length;
    const gap = othersScore - selfScore;
    const name = dimension.name || dimension.id;
    return {
      id: dimension.id,
      name,
      selfScore,
      othersScore,
      gap,
      result: dimension.result,
    };
  };

  const mapDimensionForCompare = () => {
    const selfDimension = findDimension(compare.selfDimension);
    const otherDimension = findDimension(compare.otherDimension);
    const gap = otherDimension.result - selfDimension.result;
    const name = title || `${selfDimension.name} / ${otherDimension.name}`;
    return {
      id: `${selfDimension.id}_${otherDimension.id}`,
      name,
      selfLabel: selfDimension.name,
      selfScore: selfDimension.result,
      othersLabel: otherDimension.name,
      othersScore: otherDimension.result,
      gap,
    };
  };

  /* Dimensions processing */
  let dimensions = [];

  // 1) spread subDimensions & cleanup
  assessmentResults.forEach((el) => {
    dimensions.push(el);
    if (el.subDimensions) {
      dimensions.push(...el.subDimensions);
    }
  });

  // 2) subDimensionsOf/propsDimensions filtering
  if (subDimensionsOf) {
    dimensions = findDimension(subDimensionsOf)?.subDimensions?.filter((el) => !el.hidden) ?? [];
  } else if (Array.isArray(propsDimensions)) {
    dimensions = propsDimensions.map(findDimension).filter(Boolean);
  }

  // 3) dimensionLevel filtering
  if (mode === Config.mode.classes) {
    if (dimensionLevel === 'top-level') {
      dimensions = dimensions.filter((el) => !el.parentDimension);
    }
    if (dimensionLevel === 'sub') {
      dimensions = dimensions.filter((el) => el.parentDimension);
    }
  }

  // 4) cleanup
  dimensions = dimensions.filter(({ result }) => Number.isFinite(result));
  if (!Array.isArray(propsDimensions)) {
    dimensions.sort((a, b) => sortAlphabetically(a.id, b.id));
  }

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER dimension
  const renderDimension = (dimension, index = 0) => {
    // showNumbers processing
    let showNumbers = propsShowNumbers;
    if (!showTitle
      || !dimension.name
      || [ Config.mode.compare, Config.mode.manual ].includes(mode)
    ) {
      showNumbers = false;
    }

    return (
      <div className={styles.classificationItem} key={dimension.id}>
        { showNumbers && (
          <div className={classNames([ styles.blue, styles.cardinal ])}>
            { `#${index + 1}` }
          </div>
        ) }
        <div>
          { showTitle && (
            <span className={styles.dimensionName}>
              { translate(dimension.name, replacementsList) }
            </span>
          ) }

          <div className={styles.diagramParent}>
            <LineDiagram
              score={dimension.othersScore}
              score2={dimension.selfScore}
              range={[
                Math.min(dimension.selfScore, dimension.othersScore),
                Math.max(dimension.selfScore, dimension.othersScore),
              ]}
              renderMin={mode === Config.mode.manual ? rangeFrom : renderMin}
              renderMax={mode === Config.mode.manual ? rangeTo : segmentsCount}
              style={{
                score: othersColor ?? 'primary',
                score2: selfColor ?? 'yellow',
                range: selfColor ?? 'yellow',
              }}
            />

            <div className={styles.bottomPanel}>
              <div className={styles.indBlock}>
                <div className={styles.topBlock}>
                  <div className={classNames(
                    styles.circle,
                    styles[selfColor || 'yellow'],
                  )}
                  />
                  <span>
                    { translate(selfLabel || dimension.selfLabel || 'self_lbl', replacementsList) }
                  </span>
                </div>
                <div>
                  { setDecimalSeparator(dimension.selfScore) }
                </div>
              </div>
              <div className={styles.indBlock}>
                <div className={styles.topBlock}>
                  <div className={classNames(
                    styles.circle,
                    styles[othersColor || 'blue'],
                  )}
                  />
                  <span>
                    { translate(othersLabel || dimension.othersLabel || 'others_lbl', replacementsList) }
                  </span>
                </div>
                <div>
                  { setDecimalSeparator(dimension.othersScore) }
                </div>
              </div>
              <div className={styles.indBlock}>
                <div>
                  { translate(gapLabel || 'gap_lbl', replacementsList) }
                </div>
                <div>
                  { setDecimalSeparator(dimension.gap, 1, { signDisplay: 'always' }) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  // RENDER content
  const renderContent = () => {
    switch (mode) {
      case Config.mode.classes: {
        const data = Object.values(Config.classes).reduce((acc, el) => {
          acc[el] = [];
          return acc;
        }, {});

        dimensions.forEach((dimension) => {
          const classification = Config.classes[dimension.peerClassification];
          data[classification]?.push(mapDimensionForClasses(dimension));
        });

        // Sort by gap/result depending on specs: https://blueexcellence.atlassian.net/browse/BDE-754
        data[Config.classes.clearStrength]?.sort((a, b) => b.result - a.result);
        data[Config.classes.clearWeakness]?.sort((a, b) => a.result - b.result);
        data[Config.classes.unrecognizedStrength]?.sort((a, b) => Math.abs(b.gap) - Math.abs(a.gap));
        data[Config.classes.unrecognizedWeakness]?.sort((a, b) => Math.abs(b.gap) - Math.abs(a.gap));

        // Limit displayed max dimensions
        if (limitDimensionsPerClass) {
          Object.entries(data).forEach(([ k, v ]) => {
            data[k] = v.slice(0, limitDimensionsPerClass);
          });
        }

        // Hide empty classes
        if (hideEmptyClasses) {
          Object.entries(data).forEach(([ k, v ]) => {
            if (!v.length) {
              delete data[k];
            }
          });
        }

        return Object.keys(data).map((classification) => (
          <div
            key={classification}
            className={classNames(styles.classification, { [styles.forCustomReport]: forCustomReport })}
          >
            <span className='bluTypeXs'>
              { translate(`peer_360_report_strengths_classif_${classification}`) }
            </span>
            <div className={classNames('bluTypeCopy', 'marginTopXs')}>
              { translate(`peer_360_report_strengths_classif_copy_${classification}`) }
            </div>

            { !data[classification]?.length && (
              <div className='marginTopS'>
                <Callout triangleOnTop={false}>
                  <>
                    <span className='bluTypeLabelL'>
                      { translate(`peer_360_report_strengths_classif_nodata_${classification}`) }
                    </span>
                    <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                      { translate(`peer_360_report_strengths_classif_nodata_copy_${classification}`) }
                    </div>
                  </>
                </Callout>
              </div>
            ) }

            { data[classification]?.map(renderDimension) }
          </div>
        ));
      }

      case Config.mode.select: {
        return dimensions
        .map(mapDimension)
        .map((el) => ({ ...el, name: title || el.name }))
        .map(renderDimension);
      }

      case Config.mode.largest: {
        return dimensions
        .map(mapDimension)
        .filter(({ gap }) => Number.isFinite(gap))
        .sort((a, b) => sortAlphabetically(Math.abs(a.gap), Math.abs(b.gap), true))
        .slice(0, numResults)
        .map(renderDimension);
      }
      case Config.mode.largestPositive: {
        return dimensions
        .map(mapDimension)
        .filter(({ gap }) => gap > 0)
        .sort((a, b) => sortAlphabetically(a.gap, b.gap, true))
        .slice(0, numResults)
        .map(renderDimension);
      }
      case Config.mode.smallestPositive: {
        return dimensions
        .map(mapDimension)
        .filter(({ gap }) => gap > 0)
        .sort((a, b) => sortAlphabetically(a.gap, b.gap))
        .slice(0, numResults)
        .map(renderDimension);
      }
      case Config.mode.smallest: {
        return dimensions
        .map(mapDimension)
        .filter(({ gap }) => Number.isFinite(gap))
        .sort((a, b) => sortAlphabetically(Math.abs(a.gap), Math.abs(b.gap)))
        .slice(0, numResults)
        .map(renderDimension);
      }
      case Config.mode.largestNegative: {
        return dimensions
        .map(mapDimension)
        .filter(({ gap }) => gap < 0)
        .sort((a, b) => sortAlphabetically(a.gap, b.gap))
        .slice(0, numResults)
        .map(renderDimension);
      }
      case Config.mode.smallestNegative: {
        return dimensions
        .map(mapDimension)
        .filter(({ gap }) => gap < 0)
        .sort((a, b) => sortAlphabetically(a.gap, b.gap, true))
        .slice(0, numResults)
        .map(renderDimension);
      }

      case Config.mode.compare: {
        return renderDimension(mapDimensionForCompare());
      }

      case Config.mode.manual: {
        return renderDimension({
          id: Config.mode.manual,
          name: title,
          selfScore: selfValue,
          othersScore: othersValue,
          gap: othersValue - selfValue,
        });
      }

      default:
    }
    return null;
  };

  // RENDER: GapAnalysis
  return (
    <div className={classNames(styles.gapAnalysis)}>
      { renderContent() }
    </div>
  );
};

export default GapAnalysis;
