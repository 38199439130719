// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ConfigurationError.module.scss';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import * as localStorage from 'utils/localStorage';


// COMPONENT: ConfigurationError
const ConfigurationError = (props) => {
  // PROPS
  const { error } = props;


  // RENDER: ConfigurationError
  if (!error || error.nonexistent) {
    return null;
  }

  return (
    <div className={styles.configurationError}>
      <div className={styles.content}>
        <span className='bluTypeXxs'>
          Could not initialise configuration
        </span>
        <br />
        <br />
        { error.message }
        <br />
        <br />
        <Button
          size='S'
          looks='secondary'
          onClick={() => {
            localStorage.logout();
            window.location.reload();
          }}
        >
          Retry
        </Button>
        <pre>
          { JSON.stringify(error.body, null, 2) }
        </pre>
      </div>
    </div>
  );
};

export default ConfigurationError;
