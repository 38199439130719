import React from 'react';
import styles from './SignUpSuccessForm.module.scss';
import Button from 'ui/basic/forms/Button';

const SignUpSuccessForm = (props) => {
  const {
    title, description, buttonTitle, onButtonClick,
  } = props;

  return (
    <div className={styles.signUpSuccessForm}>
      { title }
      <div className={styles.subTitle}>
        { description }
      </div>
      { buttonTitle && (
        <Button onClick={onButtonClick}>
          { buttonTitle }
        </Button>
      ) }
    </div>
  );
};

export default SignUpSuccessForm;
