// REACT, STYLE, STORIES & COMPONENT
import React, { useMemo } from 'react';
import styles from './LineDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { calculateDotRadius, clusterDistribution } from './utils';

// CONFIG & DATA
const Config = {
  size: 15,
  dotRadius: 1.5,
  defaultRenderMin: 1,
  defaultRenderMax: 10,
  defaultStyle: {
    score: 'primary',
    score2: 'yellow',
    range: 'primary',
  },
  kde: {
    bandwidth: 0.5,
    samples: 500,
  },
  clusterDistance: 0.5,
  clusterDotMaxRadius: 18,
};


// COMPONENT: LineDiagram
const LineDiagram = (props) => {
  // PROPS
  const {
    score,
    score2,
    range,
    renderMin = Config.defaultRenderMin,
    renderMax = Config.defaultRenderMax,
    style,
    distribution,
    hoveredDot,
    showKde = false,
    kdeBandwidth = Config.kde.bandwidth,
    clusterEnabled = false,
    clusterDistance: clusterDistanceOriginal = Config.clusterDistance,
  } = props;

  const clusterDistance = Math.max(clusterDistanceOriginal, 0);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const styleInternal = { ...Config.defaultStyle, ...style };
  const rangeStyle = classNames(styles.range, styles[styleInternal.range]);

  const segments = Math.abs(renderMax - renderMin);
  const step = 100 / segments;

  let rangeStart;
  let rangeEnd;
  if (range?.length) {
    rangeStart = Math.max(range[0], renderMin);
    rangeEnd = Math.min(range[1], renderMax);
  }

  const distributionInternal = useMemo(() => {
    if (!distribution) {
      return [];
    }

    if (!clusterEnabled) {
      return [ ...distribution ];
    }

    const clustered = clusterDistribution(
      distribution.map(({ value }) => value),
      clusterDistance,
    );
    return clustered.map((clusterInner) => ({
      // place cluster at average position
      value: clusterInner.reduce((current, acc) => current + acc) / clusterInner.length,
      text: clusterInner.length > 1 ? clusterInner.length : '',
      radius: calculateDotRadius(
        clusterInner.length,
        clustered[0]?.length || 0,
        {
          maxDotRadius: Config.clusterDotMaxRadius,
        },
      ),
    }));
  }, [ clusterEnabled, clusterDistance, distribution ]);

  /**
   * Calculate kernel density estimation (KDE) samples.
   * See: https://blueexcellence.atlassian.net/browse/BDE-876
   */
  const kdePoints = useMemo(() => {
    // ignore dots outside of range for calculation
    const kdeDistribution = distribution?.filter(({ value }) => value >= rangeStart && value <= rangeEnd);
    if (!showKde || !kdeDistribution?.length) {
      return [];
    }

    // kde calculation fn
    const normalDensity = (x) => Math.exp(-0.5 * (x ** 2)) / Math.sqrt(2 * Math.PI);
    const kde = (x) => kdeDistribution
    .map(({ value }) => normalDensity((x - value) / kdeBandwidth))
    .reduce((acc, el) => el + acc, 0) / kdeDistribution.length;

    // calculate kde samples on all diagram width
    const relativeRangeCoverage = (rangeEnd - rangeStart) / segments;
    const samplesCount = Math.ceil(Config.kde.samples * relativeRangeCoverage);
    const sampleDistance = (rangeEnd - rangeStart) / (samplesCount - 1);
    let points = [ ...Array(samplesCount) ]
    .map((_, i) => rangeStart + i * sampleDistance)
    .map((x) => ({ x, y: kde(x) }));

    // normalise height
    const peak = Math.max(...points.map(({ y }) => y));
    points = points.map((p) => ({ x: p.x, y: ((p.y / peak) * 100).toFixed(2) }));

    return points;
  }, [ distribution, kdeBandwidth, rangeEnd, rangeStart, segments, showKde ]);

  /**
   * Build SVG path starting from calculated KDE samples.
   */
  const kdeSvgPath = useMemo(() => {
    const getX = (x) => ((x - renderMin) * step).toFixed(2);
    return `M${getX(rangeStart)},-50
    ${kdePoints.map((p) => `L${getX(p.x)},${p.y}`).join(' ')}
    L${getX(rangeEnd)},-50
    Z`;
  }, [ kdePoints, rangeEnd, rangeStart, renderMin, step ]);


  // Note: We always use the maximum cluster size for the side padding
  // to avoid chart sizes jumping on one page.
  const sidePadding = Config.clusterDotMaxRadius;

  // RENDER: LineDiagram
  return (
    <div
      className={classNames(styles.lineDiagram, { [styles.animate]: !showKde })}
      style={{
        padding: `0 ${sidePadding + 0.2}px`, // 0.2 - some extra space
        height: Config.size * ((showKde || clusterEnabled) ? 3 : 1),
      }}
    >
      <svg height={Config.size}>
        { /* KDE RANGE */ }
        { kdePoints.length ? (
          <>
            { /* KDE WAVES */ }
            <svg
              viewBox='0 0 100 100'
              preserveAspectRatio='none'
            >
              <path
                y={Config.size / 2}
                d={kdeSvgPath}
                transform='scale(1,-1)'
                className={rangeStyle}
                strokeWidth={0}
              />
              <path
                y={Config.size / 2}
                d={kdeSvgPath}
                transform='translate(0,100)'
                className={rangeStyle}
                strokeWidth={0}
              />
            </svg>
            { /* KDE ROUNDED EDGES */ }
            <ellipse
              cx={`${(rangeStart - renderMin) * step}%`}
              cy={Config.size / 2}
              rx={Config.size / 2}
              ry={(Config.size / 2) + (kdePoints[0].y / 100) * Config.size}
              className={rangeStyle}
              strokeWidth={0}
            />
            <ellipse
              cx={`${(rangeEnd - renderMin) * step}%`}
              cy={Config.size / 2}
              rx={Config.size / 2}
              ry={(Config.size / 2) + (kdePoints[kdePoints.length - 1].y / 100) * Config.size}
              className={rangeStyle}
              strokeWidth={0}
            />
          </>
        ) : (
          /* REGULAR RANGE */
          Boolean(range?.length) && (
            <rect
              x={`${(rangeStart - renderMin) * step}%`}
              y={(Config.size - 1) / 2}
              width={`${(rangeEnd - rangeStart) * step}%`}
              height={1}
              rx={0.5}
              strokeWidth={Config.size}
              className={rangeStyle}
            />
          )) }

        { /* GREY LINE */ }
        <rect
          y={Math.floor(Config.size / 2)}
          width='100%'
          height={1}
          className={styles.grey}
        />

        { /* GREY DOTS */ }
        { new Array(segments + 1).fill().map((_, i) => (
          <circle
            key={i} // eslint-disable-line react/no-array-index-key
            cx={`${i * step}%`}
            cy={Config.size / 2}
            r={Config.dotRadius}
            className={styles.grey}
          />
        )) }

        { /* this is used as a background for big cluster dots
             to apply a transparency effect for the one defined below */ }
        { /* https://blueexcellence.atlassian.net/browse/BDE-877?focusedCommentId=28392 */ }
        { distributionInternal
        .filter((marker) => marker.radius >= Config.size / 2)
        .map((marker, i) => {
          const markerStyle = marker.style ?? styleInternal.range;
          return (
            <circle
              key={i} // eslint-disable-line react/no-array-index-key
              cx={`${(marker.value - renderMin) * step}%`}
              cy={Config.size / 2}
              r={marker.radius || Config.size / 4}
              className={classNames(
                styles.marker,
                styles[markerStyle],
                { [styles.light]: markerStyle === styleInternal.range },
              )}
            />
          );
        }) }

        { /* SCORE MARKER 1 */ }
        { Number.isFinite(score) && (
          <circle
            cx={`${(score - renderMin) * step}%`}
            cy={Config.size / 2}
            r={Config.size / 2}
            data-test='LineDiagramDot'
            className={classNames(
              styles.marker,
              styles[styleInternal.score],
            )}
          />
        ) }

        { /* SCORE MARKER 2 */ }
        { Number.isFinite(score2) && (
          <circle
            cx={`${(score2 - renderMin) * step}%`}
            cy={Config.size / 2}
            r={Config.size / 2}
            data-test='LineDiagramDot2'
            className={classNames(
              styles.marker,
              styles[styleInternal.score2],
            )}
          />
        ) }

        { /* DISTRIBUTION MARKERS */ }
        { distributionInternal?.map((marker, i) => {
          const markerStyle = marker.style ?? styleInternal.range;
          return (
            <React.Fragment key={marker.id || i}>
              <circle
                cx={`${(marker.value - renderMin) * step}%`}
                cy={Config.size / 2}
                r={marker.radius || Config.size / 4}
                className={classNames(
                  styles.marker,
                  styles[markerStyle],
                  { [styles.animating]: hoveredDot && hoveredDot === marker.id },
                  { [styles.light]: markerStyle === styleInternal.range },
                  { [styles.transparent]: marker.radius >= Config.size / 2 },
                )}
              />

              { marker.text && (
                <text
                  x={`${(marker.value - renderMin) * step}%`}
                  y={Config.size / 2}
                  className={classNames(styles.distributionText, styles[markerStyle])}
                >
                  { marker.text }
                </text>
              ) }
            </React.Fragment>
          );
        }) }
      </svg>
    </div>
  );
};

export default LineDiagram;
