const Config = {
  dotRadiusMin: 15 / 4,
  dotRadiusMax: 15,
};

export const calculateDotRadius = (
  clusterSize,
  maxClusterSize,
  {
    minDotRadius = Config.dotRadiusMin,
    maxDotRadius = Config.dotRadiusMax,
  },
) => {
  let maxDotSizeInternal = maxDotRadius;
  if (clusterSize === 1 || maxClusterSize === 1) {
    return minDotRadius;
  }

  if (maxClusterSize === 2) {
    maxDotSizeInternal = minDotRadius * 2;
  }

  if (maxClusterSize === 3) {
    maxDotSizeInternal = minDotRadius * 3;
  }

  const relativeSize = (clusterSize - 1) / (maxClusterSize - 1);
  return minDotRadius + relativeSize * (maxDotSizeInternal - minDotRadius);
};

/**
 * Groups numerical data points into clusters based on proximity.
 *
 * This function takes an array of numerical data points and groups them into clusters,
 * where each cluster consists of points that are within a specified distance (`width`) from each other.
 *
 * @param {number[]} dataToCluster - An array of numerical data points to be clustered.
 * @param {number} width - The maximum distance between any two points within the same cluster.
 * @returns {number[][]} An array of clusters, each represented as an array of numbers.
 *
 */
export const clusterDistribution = (dataToCluster, width = 0.5) => {
  if (!dataToCluster) {
    return [];
  }

  let dataToClusterInternal = [ ...dataToCluster ];
  const clusters = [];

  while (dataToClusterInternal.length > 0) {
    // Generate clusters starting or ending at each point
    const currentClusters = [
      // eslint-disable-next-line no-loop-func
      ...dataToClusterInternal.map((i) => dataToClusterInternal.filter((j) => i >= j && i - j <= width)),
    ];

    // Sort clusters by size (descending) and then by range width (ascending)
    currentClusters.sort(
      (a, b) => a.length - b.length
      || (Math.max(...b) - Math.min(...b)) - (Math.max(...a) - Math.min(...a)),
    );
    const newCluster = currentClusters[currentClusters.length - 1];

    // Store the cluster and remove its elements from the dataset
    clusters.push(newCluster);
    dataToClusterInternal = dataToClusterInternal.filter((x) => !newCluster.includes(x));
  }

  return clusters;
};
