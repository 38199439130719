// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PeerBreakdown.module.scss';

// UTILS
import { DimensionResult } from 'ui/molecules/Peer360Result/Peer360Report/components/DimensionResult';

// 3RD PARTY
import classNames from 'classnames';


// COMPONENT: PeerBreakdown
const PeerBreakdown = (props) => {
  // PROPS
  const {
    segmentsCount,
    renderMin,
    assessmentResults,
    assessmentResultsAll,
    configuration = {},
    content = {},
    blockIndex = null,
    forCustomReport = false,
  } = props;

  const {
    mode,
    includeTopLevelResult = true,
    includeOverallBeforePeers = true,
    showPeerClassification = true,
    showDistribution = false,
    clusterDistribution,
    colors,
    range,
    dotLegend,
  } = configuration;
  const {
    dimension: singleDimensionId,
    peerGroups,
  } = content;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const isSingleDimensionMode = mode === 'single-dimension' && singleDimensionId;
  // assessmentResultsAll containing all visible and hidden dimensions
  // https://blueexcellence.atlassian.net/browse/BDE-33
  let results = isSingleDimensionMode ? assessmentResultsAll : assessmentResults;

  if (mode !== 'top-level') {
    results = results.reduce((acc, el) => {
      if (includeTopLevelResult) {
        acc.push(el);
      }
      if (el.subDimensions) {
        acc.push(...el.subDimensions);
      }
      return acc;
    }, []);
    if (isSingleDimensionMode) {
      results = results.filter(({ id }) => id === singleDimensionId);
    }
  }


  // RENDER: PeerBreakdown
  return (
    <div className={styles.peerBreakdown}>
      { results
      .filter(({ result }) => Number.isFinite(result))
      .map((dimension) => {
        const isTopDimensionInBreakdownMode = !dimension.parentDimension && mode === 'dimension-breakdown';
        const isSelfOnly = dimension.peerResults?.length === 1 && dimension.peerResults[0]?.id === 'self';
        return (
          <div
            id={Number.isFinite(blockIndex) ? `${blockIndex}_${dimension.id}` : dimension.id}
            key={dimension.id}
            className={classNames(styles.dimension, { [styles.forCustomReport]: forCustomReport })}
          >
            <div className={styles.diagram}>
              <DimensionResult
                dimension={dimension}
                segmentsCount={segmentsCount}
                renderMin={renderMin}
                showPeerClassification={!isTopDimensionInBreakdownMode && showPeerClassification}
                showDistribution={showDistribution}
                clusterDistribution={clusterDistribution}
                includeOverallBeforePeers={isTopDimensionInBreakdownMode || includeOverallBeforePeers}
                showPeerResults={!(isTopDimensionInBreakdownMode || isSelfOnly)}
                peerGroups={peerGroups}
                colors={colors}
                range={range}
                showDotLegend={(isTopDimensionInBreakdownMode && dotLegend?.showOnTopLevelResult)
                  || (!isTopDimensionInBreakdownMode
                    && includeOverallBeforePeers
                    && dotLegend?.showOnOverallBeforePeers)}
              />
            </div>
          </div>
        );
      }) }
    </div>
  );
};

export default PeerBreakdown;
