// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, useRef, Suspense, lazy,
} from 'react';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import {
  Switch, Route, Redirect, useHistory, useLocation,
} from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { BotAssistant, Button } from 'ui/basic';
import { ModalsAndPanels } from './ModalsAndPanels';
import { Footer } from './Footer';

// UTILS
// import { useTranslate } from 'utils/translator';
import { useDebounce, useBreakpoint } from 'utils/hooks';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';
import {
  IS_MAINTENANCE_MODE,
  CONFIGURATION,
  CONFIGURATION_OPTIONS,
} from 'utils/configuration';
import { COMPANY_FEATURES_NEXT } from 'utils/configuration/const/settings';
import {
  getCoachHubParam, getIsExternalSystemIntegrationSupported,
} from 'features/+coachHub/utils/localStorage';
import { COACHHUB_TARGETS } from 'features/+coachHub/routeNext';
import * as localStorage from 'utils/localStorage';

// STORE
import store from 'store';

// STORE NEXT
import { Provider, useSelector, useDispatch } from 'react-redux';
import { storeNext } from 'features/framework/utils/storeNext';
// configurationSlice
import {
  selectError,
  selectInstanceIsAdminFrontend,
  selectIsLoggedIn,
  selectInstanceIsNonexistent,
  selectUserProfileCompleted,
  selectRoutesWithStateExtras,
  selectPlanRestricted,
  selectUserGroup,
  selectPlanPaymentCard,
  selectFeature,
} from 'features/framework/storeNext/configurationSlice';

import {
  setSubNavIsOpen as setSubNavIsOpenStore,
} from 'features/framework/storeNext/layoutSlice';
import { ConfigurationError } from './ConfigurationError';
import { StandaloneWrapper } from './StandaloneWrapper';
import { NestedWrapper } from './NestedWrapper';
import { WrapperCoachHub } from './WrapperCoachHub';
import { ModalWrapper } from './ModalWrapper';
import { PageWrapper } from './PageWrapper';
import { MainHeader } from './MainHeader';
import { MainNav } from './MainNav';
import xsTransitions from './xsTransitions.module.scss';
import transitions from './transitions.module.scss';
import styles from './MainLayout.module.scss';
// store next debug tools
// import { Counter } from 'features/framework/components/Counter';
// import { Configuration } from 'features/framework/components/Configuration';
// import { ConfigurationInside } from 'features/framework/components/ConfigurationInside';

// LAZY REDUCERS
const AdminUGReducers = lazy(() => import('features/+adminUG/pages/AdminUGReducers' /* webpackChunkName: "adminUG" */));


// CONFIG & DATA
// const Config = {};

// CONTEXT
export const LayoutContext = React.createContext();

// COMPONENT: MainLayout
const MainLayout = ({
  loading,

  // it is used for 'showTranslationKeys' global function to toggle on the translation keys
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  translationToggle,
}) => {
  // PROPS

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { pathname } = location;

  // STORE: CONFIGURATION
  const error = useSelector(selectError);
  const isAdminFrontend = useSelector(selectInstanceIsAdminFrontend);
  const userIsLoggedIn = useSelector(selectIsLoggedIn);
  const isNonexistent = useSelector(selectInstanceIsNonexistent);
  const hasAssistantFeature = useSelector((state) => selectFeature(state, COMPANY_FEATURES_NEXT.FEATURE_ASSISTANT));

  const isCoachhub = CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB;
  const showAssistant = hasAssistantFeature && !isCoachhub && !pathname.startsWith('/assessments/');

  // Redirect towards error pages on mount
  useEffect(() => {
    if (IS_MAINTENANCE_MODE) {
      history.push('/maintenance');
      return;
    }

    // https://blueexcellence.atlassian.net/browse/BQDQ-1064
    if (isNonexistent && (!pathname.includes('/legal') && !pathname.includes('trial-signup'))) {
      history.push('/nonexistent-instance');
    }
  }, [ history, isNonexistent, pathname ]);

  const [ redirectsDone, setRedirectsDone ] = useState();
  const planRestricted = useSelector(selectPlanRestricted);
  const planPaymentCard = useSelector(selectPlanPaymentCard);
  // const [ showRoutes, setShowRoutes ] = useState();
  const userProfileCompleted = useSelector(selectUserProfileCompleted);
  const userGroup = useSelector(selectUserGroup);
  useEffect(() => {
    if (!redirectsDone && !loading) {
      setRedirectsDone(true);
    }

    // if (userIsLoggedIn) {

    //   if (userProfileCompleted) {

    //     // from login to main page
    //     if (location.pathname.includes('login')) {
    //       if (isAdminFrontend) {
    //         history.push('/admin/overview')
    //       }
    //       else {
    //         history.push('/')
    //       }
    //     }

    //     // from / to /admin/overview
    //     if (isAdminFrontend && location.pathname === '/') {
    //       history.push('/admin/overview')
    //     }

    //     // legal
    //     if (location.pathname.includes === '/legal') {
    //       history.push('legal/data-protection-privacy')
    //     }

    //     // MISSING: <Redirect from='/*' to='/' />
    //   }
    //   // userProfile not completed
    //   else {
    //     history.push('/complete-profile')
    //   }
    // }
    // // not logged in
    // else {
    //   if (location.pathname === '/') {
    //     history.push('/login')
    //   }
    //   // MISSING <Redirect from='/*' to='/login' />
    // }
  // }
  }, [ location, history ]);


  // ROUTING
  // configurationSlice
  // NOTE: think this causes a double render, should revert to selectRoutes asap
  const routes = useSelector(selectRoutesWithStateExtras);

  // scroll to top on route changes
  const nestedPageEl = useRef(null);
  useEffect(() => {
    const unListen = history.listen(() => {
      if (nestedPageEl) {
        nestedPageEl.current.scrollTo(0, 0);
      }
    });
    return () => {
      unListen();
    };
  }, [ history ]);

  // RESPONSIVE
  const bp = useBreakpoint();

  // MAIN NAV: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ mainNavIsOpen, setMainNavIsOpen ] = useState(true);
  const mainNavIsOpenDebounced = useDebounce(mainNavIsOpen, 600);
  const [ fullMode/* , setFullMode */ ] = useState(false);
  // some motion graphics & prevent scrolling / close menu via scroll for mobile
  useEffect(() => {
    // let timeout;
    if (bp.isXs) {
      if (mainNavIsOpen) {
        // timeout = setTimeout(() => {
        //   setFullMode(!mainNavIsOpen);
        // }, 200);
        disableScrollingOnBody();
      } else {
        // setFullMode(!mainNavIsOpen);
        enableScrollingOnBody();
      }
      return () => {
        // clearTimeout(timeout);
      };
    }

    // setFullMode(mainNavIsOpen);
    return undefined;
  }, [ mainNavIsOpen, bp ]);
  // on bp change => automatic opening and closing of main nav
  useEffect(() => {
    if (bp.isXs || bp.isS || bp.isM) {
      setMainNavIsOpen(false);
    } else {
      setMainNavIsOpen(true);
    }
  }, [ bp ]);
  const [ mainNavTransitions, setMainNavTransitions ] = useState({ ...transitions });
  useEffect(() => {
    setMainNavTransitions(bp.isXs ? { ...xsTransitions } : { ...transitions });
  }, [ bp.isXs ]);

  // SUBNAV
  const [ subNavIsOpen, setSubNavIsOpen ] = useState(false);
  useEffect(() => {
    dispatch(setSubNavIsOpenStore(subNavIsOpen));
    // console.log('subNavIsOpen', subNavIsOpen);
  }, [ subNavIsOpen, dispatch ]);

  // MODALS & PANELS
  const [ profileIsOpen, setProfileIsOpen ] = useState(false);

  const storePathnameForRedirect = () => {
    const subStrings = [ '/share/', '/external/', '/ext/' ];

    const routesForNonAuthorized = Object.values(routes).flat()
    .filter((route) => route.show?.needsLogout || route.show?.needsLogin === false)
    .map((route) => route.path);

    if (pathname !== '/'
      && !subStrings.some((subString) => pathname.includes(subString))
      && !routesForNonAuthorized.includes(pathname)
    ) {
      localStorage.setItem('redirect', pathname);
    }
  };

  // RENDER: list of router redirects
  const renderRedirects = () => {
    /* CoachHub case */
    if (getIsExternalSystemIntegrationSupported() || pathname === '/action-link') {
      const target = getCoachHubParam('target');
      if (localStorage.getItem('token') && Object.values(COACHHUB_TARGETS).includes(target)) {
        return null;
      }

      if (pathname !== '/action-link') {
        return <Redirect from='/*' to={`/action-link?redirect=${encodeURIComponent(pathname)}`} />;
      }

      return null;
    }

    /* not logged in */
    if (!userIsLoggedIn) {
      storePathnameForRedirect();

      return <Redirect from='/*' to='/login' />;
    }

    /* if plan is restricted and user has no access to platform */
    if (planRestricted) {
      if (planPaymentCard.status !== 'expired') {
        return <Redirect from='/*' to='/upgrade-from-trial' />;
      }
      return <Redirect from='/*' to='/update-payment-method' />;
    }

    /* profile completion required */
    if (!userProfileCompleted) {
      return <Redirect from='/*' to='/complete-profile' />;
    }

    /* regularly logged in user */
    const redirects = [];
    if (!isAdminFrontend) {
      redirects.push(<Redirect key='r0' from='/login' to='/' />);
    }
    redirects.push(
      <Redirect key='r1' from='/legal' to='/legal/data-protection-privacy' />,
      <Redirect key='r2' from='/*' to='/' />, // catch all
    );
    return redirects;
  };

  // RENDER: MainLayout
  return (
    // LAYOUT CONTEXT TO AVOID PROP DRILLING
    <LayoutContext.Provider value={{
      mainNavIsOpen,
      mainNavIsOpenDebounced,
    }}
    >
      <div className={styles.mainLayout}>
        { /* CONFIGURATION ERROR */ }
        <ConfigurationError error={error} />

        { /* MainNav */ }
        <nav
          className={classNames(styles.mainNav, {
            [styles.closed]: !mainNavIsOpen,
          })}
          style={{ ...mainNavTransitions }}
        >
          <Provider store={store}>
            <MainNav
              mainNavRoutes={routes.mainNavRoutes}
              onClose={() => { setMainNavIsOpen(false); }}
            />
          </Provider>
        </nav>

        { /* Main */ }
        <main className={classNames(styles.main, {
          [styles.fullWidth]: !mainNavIsOpen,
          [styles.subNavIsOpen]: subNavIsOpen,
        })}
        >

          { /* Header */ }
          <header className={styles.header}>
            <MainHeader
              showBurgerMenu={fullMode || !mainNavIsOpen}
              onOpenProfile={() => setProfileIsOpen(true)}
              onOpenMainNav={(open) => setMainNavIsOpen(open === false ? false : open)}
              onSubNav={(open) => setSubNavIsOpen(open)}
            />
          </header>

          { /* Page */ }
          <section ref={nestedPageEl} className={styles.page}>
            { /* fallback while lazy modules are loaded */ }

            <Suspense fallback={<></>}>
              { userGroup === 'admin' && (
                <AdminUGReducers />
                // <h1>ADMIN</h1>
              ) }
              { /* Master Switch Pre Initalisation */ }
              { /* prevent redirects before config initialisation */ }
              { /* { !initialisedExtended && ( */ }
              { loading && (
                <Switch>
                  { routes.allRoutes.map(({ path }) => (
                    <React.Fragment key={path}>{ path }</React.Fragment>
                  )) }
                </Switch>
              ) }

              { /* Master Switch */ }
              { /* { initialisedExtended && ( */ }
              { !loading && (
                <Switch>

                  { /* Standalone Routes */ }
                  { routes.standaloneRoutes.map((route) => {
                    const { path, PageComponent, useStoreNext } = route;
                    return (
                      <Route key={path} path={path}>
                        <PageWrapper route={route}>
                          <StandaloneWrapper {...route.pageWrapper}>
                            <Provider store={useStoreNext ? storeNext : store}>
                              <PageComponent />
                            </Provider>
                          </StandaloneWrapper>
                        </PageWrapper>
                      </Route>
                    );
                  }) }

                  { /* Modal Routes */ }
                  { routes.modalRoutes.map((route) => {
                    const { path, PageComponent, useStoreNext } = route;

                    return (
                      <Route key={path} path={path}>
                        <PageWrapper route={route}>
                          <ModalWrapper
                            noAnimate={route.pageWrapper?.noAnimate}
                            instantBackground={route.pageWrapper?.instantBackground}
                          >
                            <Provider store={useStoreNext ? storeNext : store}>
                              <PageComponent />
                            </Provider>
                          </ModalWrapper>
                        </PageWrapper>
                      </Route>
                    );
                  }) }

                  { /* CoachHub Routes */ }
                  { routes.coachHubRoutes.map((route) => {
                    const { path, PageComponent, useStoreNext } = route;

                    return (
                      <Route exact key={path} path={path}>
                        <PageWrapper route={route}>
                          { /* wrapper overlays main layout and gives margins */ }
                          <WrapperCoachHub
                            noAnimate={route.pageWrapper?.noAnimate}
                            noFramework={route.pageWrapper?.noFramework}
                          >
                            <Provider store={useStoreNext ? storeNext : store}>
                              <PageComponent />
                            </Provider>
                          </WrapperCoachHub>
                        </PageWrapper>
                      </Route>
                    );
                  }) }

                  { /* Nested Routes */ }
                  { routes.nestedRoutes.map((route) => {
                    const {
                      path, PageComponent, subRoutes, useStoreNext,
                    } = route;

                    const routes = [];

                    // route
                    routes.push((
                      <Route exact key={path} path={path}>
                        <div
                          className={classNames(styles.nestedPage, {
                            [styles.white]: route.pageWrapper?.background === 'white',
                          })}
                          data-test='MainLayoutNested'
                        >
                          <div className={styles.pageWrapper}>
                            <PageWrapper route={route}>
                              <NestedWrapper autoMargins={route.pageWrapper?.autoMargins}>
                                <Provider store={useStoreNext ? storeNext : store}>
                                  <PageComponent />
                                </Provider>
                              </NestedWrapper>
                            </PageWrapper>
                          </div>
                          <div className={styles.footer}>
                            <Footer />
                          </div>

                          { /* store next debug */ }
                          { /* <br/>
                        <br/>
                        <h6>StoreNext</h6>
                        <Provider store={storeNext}>
                          <Provider store={storeNext}>
                            <Configuration />
                            <Counter/>
                          </Provider>
                          <Counter>
                            <Provider store={store}>
                              <ConfigurationInside/>
                            </Provider>
                          </Counter>
                          <Counter/>
                          <Counter/>
                        </Provider> */ }

                        </div>
                      </Route>
                    ));

                    // subRoutes
                    if (subRoutes?.length) {
                      subRoutes.forEach((subRoute) => {
                        const {
                          path, PageComponent, subRoutes: subSubRoutes, useStoreNext,
                        } = subRoute;
                        // PageComponent exists?
                        // subRoutes can just be a inSubNav placeholder for their parent route
                        if (!PageComponent) return;
                        routes.push((
                          <Route exact key={path} path={path}>
                            <div
                              className={classNames(styles.nestedPage, {
                                [styles.white]: route.pageWrapper?.background === 'white',
                              })}
                              data-test='MainLayoutNested'
                            >
                              <div className={styles.pageWrapper}>
                                <PageWrapper route={subRoute}>
                                  <NestedWrapper autoMargins={subRoute.pageWrapper?.autoMargins}>
                                    <Provider store={useStoreNext ? storeNext : store}>
                                      <PageComponent />
                                    </Provider>
                                  </NestedWrapper>
                                </PageWrapper>
                              </div>
                              <div className={styles.footer}>
                                <Footer />
                              </div>
                            </div>
                          </Route>
                        ));

                        // subSubRoutes
                        if (subSubRoutes?.length) {
                          subSubRoutes.forEach((subSubRoute) => {
                            const { path, PageComponent, useStoreNext } = subSubRoute;
                            // PageComponent exists?
                            // subRoutes can just be a inSubNav placeholder for their parent route
                            if (!PageComponent) return;
                            routes.push((
                              <Route exact key={path} path={path}>
                                <div
                                  className={classNames(styles.nestedPage, {
                                    [styles.white]: route.pageWrapper?.background === 'white',
                                  })}
                                  data-test='MainLayoutNested'
                                >
                                  <div className={styles.pageWrapper}>
                                    <PageWrapper route={subSubRoute}>
                                      <NestedWrapper autoMargins={subSubRoute.pageWrapper?.autoMargins}>
                                        <Provider store={useStoreNext ? storeNext : store}>
                                          <PageComponent />
                                        </Provider>
                                      </NestedWrapper>
                                    </PageWrapper>
                                  </div>
                                  <div className={styles.footer}>
                                    <Footer />
                                  </div>
                                </div>
                              </Route>
                            ));
                          });
                        }
                      });
                    }

                    return routes;
                  }) }

                  { /* REDIRECTS */ }
                  { renderRedirects() }

                </Switch>
              ) }
            </Suspense>
          </section>

          { /* BLUQUIST ASSISTANT */ }
          { showAssistant && <BotAssistant /> }
        </main>

        { /* MODALS AND PANELS */ }
        { /* { showRoutes && !planRestricted && userProfileCompleted && ( */ }
        { !planRestricted && userProfileCompleted && (
          <Provider store={store}>
            <ModalsAndPanels
              profileIsOpen={profileIsOpen}
              onProfileClose={() => setProfileIsOpen(false)}
            />
          </Provider>
        ) }

      </div>
    </LayoutContext.Provider>
  );
};

export default MainLayout;
