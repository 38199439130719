// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './Prices.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { BigToggle, Button } from 'ui/basic';
import { SkeletonFlexible } from 'ui/basic/micro-ui/SkeletonFlexible';

// UTILS
import { useTranslate } from 'utils/translator/translator';
import * as localStorage from 'utils/localStorage';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'
import * as api from 'api';
import {
  selectPlanRestricted,
  selectPlanSubscriptionCanceled,
} from 'features/framework/storeNext/configurationSlice';
import { logoutFulfilled } from 'store/actions';

// CONFIG & DATA
import { CURRENCY_SYMBOLS, PLANS, Config as PaymentConfig } from 'features/+adminUG/config/payment.config';

const Config = {
  monthsInOneYear: 12,
};


// COMPONENT: Prices
const Prices = (props) => {
  // PROPS
  const {
    extras = {},
    confirm = () => {},
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  const planRestricted = useSelector(selectPlanRestricted);
  const subscriptionCanceled = useSelector(selectPlanSubscriptionCanceled);

  // PLAN: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ plan, setPlan ] = useState();
  useEffect(() => {
    if (plan) {
      return;
    }

    const answer = extras.state.answers[0];
    if (answer) {
      setPlan(answer.plan);
    } else {
      setPlan(PLANS.ANNUAL);
    }
  }, [ extras, plan ]);


  // PRICES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ prices, setPrices ] = useState();
  useEffect(() => {
    if (!plan) {
      return;
    }

    api.get('/core/company/billing/plan/prices', { plan })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        const pricesInternal = data.prices;

        if (pricesInternal) {
          let userPriceMonthly;
          let userPriceAnnual;
          if (plan === PLANS.ANNUAL) {
            userPriceMonthly = (pricesInternal.user?.pricePerUnit / PaymentConfig.stripePriceDivider) / Config.monthsInOneYear;
            userPriceAnnual = pricesInternal.user?.pricePerUnit / PaymentConfig.stripePriceDivider;
          } else {
            userPriceMonthly = pricesInternal.user?.pricePerUnit / PaymentConfig.stripePriceDivider;
            userPriceAnnual = (pricesInternal.user?.pricePerUnit / PaymentConfig.stripePriceDivider) * Config.monthsInOneYear;
          }

          setPrices({
            candidate: {
              ...pricesInternal.candidate,
              pricePerUnit: pricesInternal.candidate?.pricePerUnit / PaymentConfig.stripePriceDivider,
            },
            user: {
              ...pricesInternal.user,
              pricePerUnitMonthly: userPriceMonthly,
              pricePerUnitAnnual: userPriceAnnual,
            },
            nineLevels: {
              ...pricesInternal.assessments['9levels'],
              pricePerUnit: pricesInternal.assessments['9levels']?.pricePerUnit / PaymentConfig.stripePriceDivider,
            },
            rmp: {
              ...pricesInternal.assessments?.rmp,
              pricePerUnit: pricesInternal.assessments?.rmp?.pricePerUnit / PaymentConfig.stripePriceDivider,
            },
          });
        }
      } else {
        setPrices([]);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ plan ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleContinue = () => {
    const answer = {
      plan,
      isValid: true,
    };

    confirm(answer);
  };

  // RENDER: Prices
  return (
    <div className={classNames(styles.prices)}>
      <div className='bluContent666'>
        <div className='bluTypeS'>
          { planRestricted
            ? (subscriptionCanceled ? translate('upgrade_to_pp_title_v3') : translate('upgrade_to_pp_title_v2'))
            : translate('upgrade_to_pp_title') }
        </div>

        <div className='marginTopS'>
          <BigToggle
            activeIndex={plan === PLANS.ANNUAL ? 0 : 1}
            inlineStyling
            options={[
              { value: 'annual', label: translate('annual') },
              { value: 'monthly', label: translate('monthly') },
            ]}
            onChange={(value) => {
              if (value === 0) {
                setPlan(PLANS.ANNUAL);
              } else if (value === 1) {
                setPlan(PLANS.MONTHLY);
              }
            }}
          />
        </div>

        <div className={classNames('bluTypeCopyStrong', 'marginTopS')}>
          { plan === PLANS.ANNUAL ? translate('upgrade_to_pp_annual_copy') : translate('upgrade_to_pp_monthly_copy') }
        </div>

        { !prices && (
          <div className='marginTopS'>
            <SkeletonFlexible />
          </div>
        ) }

        { (prices && Object.keys(prices).length === 0) && (
          <div className={classNames('bluTypeCopy', 'marginTopS')}>
            Prices not found for this plan
          </div>
        ) }

        { (prices && Object.keys(prices).length > 0) && (
          <div className={styles.tableItems}>
            <div className={styles.tableItem}>
              <span>{ translate('upgrade_to_pp_prices_header') }</span>
              <span>{ translate('price') }</span>
            </div>

            <div className={styles.tableItem}>
              <span>{ translate('upgrade_to_pp_prices_user') }</span>
              <span>
                { `${prices?.user?.pricePerUnitMonthly} ${CURRENCY_SYMBOLS[prices?.user?.currency]} / ${translate('month')} (${prices?.user?.pricePerUnitAnnual} ${CURRENCY_SYMBOLS[prices?.user?.currency]} / ${translate('year')})` }
              </span>
            </div>

            <div className={styles.tableItem}>
              <span>{ translate('upgrade_to_pp_prices_candidate') }</span>
              <span>
                { `${prices?.candidate?.pricePerUnit} ${CURRENCY_SYMBOLS[prices?.candidate?.currency]}` }
                { ' ' }
                /
                { ' ' }
                { translate('profile') }
              </span>
            </div>

            <div className={styles.tableItem}>
              <span>{ translate('upgrade_to_pp_prices_rmp') }</span>
              <span>
                { `${prices?.rmp?.pricePerUnit} ${CURRENCY_SYMBOLS[prices?.rmp?.currency]}` }
                { ' ' }
                / Assessment
              </span>
            </div>

            <div className={styles.tableItem}>
              <span>{ translate('upgrade_to_pp_prices_9levels') }</span>
              <span>
                { `${prices?.nineLevels?.pricePerUnit} ${CURRENCY_SYMBOLS[prices?.nineLevels?.currency]}` }
                { ' ' }
                / Assessment
              </span>
            </div>
          </div>
        ) }

        <div className={styles.controls}>
          { planRestricted && (
            <Button
              size='S'
              looks='secondary'
              onClick={() => {
                localStorage.logout();
                dispatch(logoutFulfilled());
                window.location.href = '/logged-out';
              }}
            >
              { translate('pp_button_logout') }
            </Button>
          ) }

          <div className={styles.continueButton}>
            <Button
              size='S'
              onClick={handleContinue}
            >
              { translate('continue_lbl') }

            </Button>
          </div>
        </div>

        { (prices && Object.keys(prices).length > 0) && (
          <div className={classNames('bluTypeCopy', 'marginTopS')}>
            { translate('upgrade_to_pp_hint') }
          </div>
        ) }
      </div>
    </div>
  );
};

export default Prices;
