import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';

// 3RD PARTY
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { AssessmentNext } from 'ui/molecules/AssessmentNext';

// UTILS
import { apiNext } from 'apiNext';
import { eventBus } from 'architecture/eventBus';
import {
  ASSESSMENT_RECURRENCE_TYPES as RECURRENCE_TYPES,
  ASSESSMENT_TYPES,
} from 'utils/configuration/const/assessment-types';
import {
  initialiseAssessment,
  handleAssessmentAnswer,
} from 'utils/assessment';
import { useTranslate } from 'utils/translator';

// STORE
import { getCurrentUserId } from 'store/selectors/currentUser';
import { selectAssessments } from 'store/selectors/assessment';
import { getAssessmentHistoryFulfilled } from 'store/actions';


const IstAssessmentPage = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();

  // STORE
  const userId = useSelector(getCurrentUserId);

  const assessmentsFromStore = useSelector(selectAssessments);

  const assessmentId = ASSESSMENT_TYPES.IST;
  const istAssessment = assessmentsFromStore.find(({ id }) => id === assessmentId);

  const registrationData = istAssessment.history?.results?.[0]?.registrationData;

  const recurring = [ RECURRENCE_TYPES.RUN, RECURRENCE_TYPES.FREQUENCY, RECURRENCE_TYPES.MANUAL ]
  .includes(istAssessment?.recurrenceType);

  // GET ASSESSMENT DATA
  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ assessmentQuestions, setAssessmentQuestions ] = useState();
  const [ requiredQuestions, setRequiredQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();

  useEffect(() => {
    if (!istAssessment || istAssessment.history
      || !recurring || !istAssessment?.resultsAvailable
    ) {
      return;
    }

    apiNext.get(`/core/assessments/${assessmentId}/result/history`, { limit: 20, offset: 0 })
    .then((res) => {
      dispatch(getAssessmentHistoryFulfilled({
        id: assessmentId,
        history: res,
        offset: 0,
      }));
    });
  }, [ dispatch, istAssessment, recurring, configOverride ]);

  // initialise assessment
  useEffect(() => {
    if (!userId || !assessmentId || loadingAssessment
      || (recurring && istAssessment?.resultsAvailable && !istAssessment.history)
    ) {
      return;
    }

    setLoadingAssessment(true);

    initialiseAssessment({
      userId,
      assessmentId,
      handleExit: () => {
        history.goBack();
      },
    })
    .then(({ questions, assessment, prevAnswers }) => {
      setConfigOverride({
        title: assessment.title,
        description: assessment.description,
        allowBackNavigation: assessment.free_navigation,
        canContinueLater: assessment.multi_session,
        containsConditionalQuestions: true,
        progress: assessment.progress,
        prevAnswers,
        skipRegistration: Boolean(assessment.registered),
        assessmentDetails: {
          ...assessment,
          registrationData,
          lastIterationDate: istAssessment.history?.results[0]?.date,
        },
      });

      const inflatedQuestions = (questions ?? []).map((el) => ({ ...el, hideHelp: true }));

      setAssessmentQuestions(inflatedQuestions);
      const requiredQuestionsInternal = inflatedQuestions
      .filter(({ answerRequired }) => answerRequired)
      .map(({ id }) => id);
      setRequiredQuestions(requiredQuestionsInternal);
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [
    loadingAssessment,
    history,
    userId,
    assessmentId,
    assessmentQuestions,
    registrationData,
    istAssessment,
    recurring,
  ]);

  // METHODS

  // HANDLES
  const handleCancel = () => {
    history.goBack();
  };

  const handleFinish = () => {
    history.push('/my-profile');
  };

  const [ waitApiRequest, setWaitApiRequest ] = useState(false);
  const handleAnswer = (answer) => {
    if ([ null, undefined ].includes(answer?.answer)) {
      return;
    }

    setWaitApiRequest(true);

    const answerInternal = { ...answer };
    const overrideAnswer = Array.isArray(answer.answer);

    if (overrideAnswer) {
      answerInternal.answer = { options: answerInternal.answer };
    }

    handleAssessmentAnswer(answerInternal, assessmentId, false, overrideAnswer)
    .then((response = {}) => {
      const requiredQuestionsInternal = response.requiredQuestions;
      if (requiredQuestionsInternal) {
        setRequiredQuestions(requiredQuestionsInternal);
      }

      setWaitApiRequest(false);
    });
  };

  const handleAllAnswers = (answers, setFinishable) => {
    setFinishable(true);
  };

  const handleError = useCallback((errorMessage) => {
    eventBus.dispatch('assessmentNext.error', {
      message: errorMessage || translate('error_something_went_wrong'),
    });
  }, [ translate ]);

  // RENDER: IstAssessmentPage
  return (
    <AssessmentNext
      type={assessmentId}
      questions={assessmentQuestions}
      requiredQuestions={requiredQuestions}
      userId={userId}
      configOverride={configOverride}
      waitApiRequest={waitApiRequest}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer} // session prolongation
      onAllAnswers={handleAllAnswers}
      onError={handleError}
    />
  );
};

export default IstAssessmentPage;
