// REACT, STYLE, STORIES & COMPONENT
import React, {
  useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle,
} from 'react';
import styles from './ProfileSummary.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';


// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  BluCSSTransition,
  Button,
  CollapsibleNext,
  HotChipsPeopleWithSearch,
  LabelCheckmark,
  Link,
  Modal,
  OptionsMenu,
} from 'ui/basic';
import { VacancyUsers } from '../VacancyUsers';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { handleRipple } from 'utils/ripple';
import { VACANCY_STATUSES } from 'utils/configuration/const/recruiting';

// STORE
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as api from 'api';
import { showToast } from 'store/actions';
import * as fromActions from 'features/+candidates/store/vacancy/vacancy.actions';
import { selectCapabilitiesNext } from 'store/selectors/configuration';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

import ShareVacancy from '../ShareVacancy';
import { getFullName } from 'utils/users';


// CONFIG & DATA
// const Config = {};

const OPTION_TYPES = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  DUPLICATE: 'DUPLICATE',
  SHARE: 'SHARE',
};

const STATUSES_TO_CHANGE = {
  [VACANCY_STATUSES.OPEN]: [
    { value: VACANCY_STATUSES.STAFFED, labelKey: 'vacancy_status_staffed' },
    { value: VACANCY_STATUSES.ARCHIVED, labelKey: 'vacancy_status_archived' },
  ],
  [VACANCY_STATUSES.STAFFED]: [
    { value: VACANCY_STATUSES.OPEN, labelKey: 'vacancy_status_open' },
    { value: VACANCY_STATUSES.ARCHIVED, labelKey: 'vacancy_status_archived' },
  ],
  [VACANCY_STATUSES.ARCHIVED]: [
    { value: VACANCY_STATUSES.OPEN, labelKey: 'vacancy_status_open' },
    { value: VACANCY_STATUSES.STAFFED, labelKey: 'vacancy_status_staffed' },
  ],
};

const VACANCY_INFO = [
  { value: 'startDateString', labelKey: 'start_termin' },
  { value: 'location', labelKey: 'location' },
  { value: 'salaryRange', labelKey: 'salary_range' },
  { value: 'employmentType', labelKey: 'employment_type' },
  { value: 'utilizationString', labelKey: 'workload' },
  { value: 'roleCareerLevel', labelKey: 'role_career_level' },
  { value: 'additionalInfo', labelKey: 'other_notices' },
];


// COMPONENT: ProfileSummary
const ProfileSummary = (props, ref) => {
  // PROPS
  const { vacancyBaseInfo, teamClickable = true, showControls = true } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const noEditing = !capabilitiesNext.vacanciesEdit;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const transitionRef = useRef();

  // MENU OPTIONS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ options, setOptions ] = useState([]);
  useEffect(() => {
    const newOptions = [];
    if (capabilitiesNext.vacanciesCreate) {
      newOptions.push({ value: OPTION_TYPES.DUPLICATE, label: translate('duplicate_vacancy') });
      newOptions.push({ value: OPTION_TYPES.DELETE, label: translate('delete_vacancy') });
    } else if (capabilitiesNext.vacanciesEdit) {
      newOptions.push({ value: OPTION_TYPES.DELETE, label: translate('delete_vacancy') });
    }

    if (me.roleNext !== 'employee'
      || (me.subRoleNext.includes('vacancyManager') || me.subRoleNext.includes('vacancyCollaborator'))
    ) {
      newOptions.push({ value: OPTION_TYPES.SHARE, label: translate('share_vacancy') });
    }

    if (vacancyBaseInfo.status === VACANCY_STATUSES.OPEN
      && (capabilitiesNext.vacanciesCreate || capabilitiesNext.vacanciesEdit)
    ) {
      newOptions.unshift({ value: OPTION_TYPES.EDIT, label: translate('edit_vacancy') });
    }

    setOptions(newOptions);
  }, [
    translate,
    me.subRoleNext,
    me.roleNext,
    capabilitiesNext,
    vacancyBaseInfo.status,
  ]);

  // VACANCY STATUS MENU: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ statusMenuVisible, setStatusMenuVisible ] = useState(false);
  const [ statusMenuOptions, setStatusMenuOptions ] = useState([]);

  const [ selectedStatus, setSelectedStatus ] = useState();

  const [ closeVacancyModalVisible, setCloseVacancyModalVisible ] = useState(false);
  const [ closeVacancyHeader, setCloseVacancyModalHeader ] = useState(translate('staff_vacancy'));
  const [ closeVacancyMode, setCloseVacancyMode ] = useState();
  const [ closeVacancyProcessing, setCloseVacancyProcessing ] = useState(false);

  const [ openVacancyModalVisible, setOpenVacancyModalVisible ] = useState(false);
  const [ openVacancyProcessing, setOpenVacancyProcessing ] = useState(false);
  const [ openVacancyHeader, setOpenVacancyHeader ] = useState(translate('open_vacancy_link_with_team'));
  const [ openVacancyStep, setOpenVacancyStep ] = useState(1);
  const [ openVacancyStep3Option, setOpenVacancyStep3Option ] = useState();
  const [ openVacancyTeamPositionId, setOpenVacancyTeamPositionId ] = useState();

  const [ teams, setTeams ] = useState([]);
  const mapSearchResultsVacancy = useCallback(({ teams: thisTeams }) => thisTeams.map((team) => ({
    value: team.id,
    label: team.name,
    positions: team.positions,
  })), []);


  const handleTeamsUpdate = (thisTeams) => {
    if (thisTeams.length === 0) {
      setTeams([]);
      return;
    }

    // only 1 team should be selected.
    // Always the last added team is selected, which is under index 0
    setTeams([ thisTeams[0] ]);
  };

  const [ linkWithTeam, setLinkWithTeam ] = useState(false);

  useEffect(() => {
    if (!vacancyBaseInfo.status) {
      return;
    }

    setStatusMenuOptions(STATUSES_TO_CHANGE[vacancyBaseInfo.status].map((status) => ({
      value: status.value,
      label: translate(status.labelKey),
    })));
  }, [ vacancyBaseInfo.status, statusMenuOptions.length, translate ]);

  const openVacancy = () => {
    // STEP 1: no need to link with a team
    if (openVacancyStep === 1 && !linkWithTeam) {
      setOpenVacancyProcessing(true);
      api.put(`recruiting/jobs/${vacancyBaseInfo.id}/status`, { status: VACANCY_STATUSES.OPEN })
      .then(({ ok, status }) => {
        setOpenVacancyProcessing(false);
        if (ok && status === 200) {
          setOpenVacancyModalVisible(false);
          dispatch(fromActions.getVacancy({ id: vacancyBaseInfo.id, loading: false }));
        }
      });

      return;
    }

    // STEP 1: link with team
    if (openVacancyStep === 1 && linkWithTeam) {
      setOpenVacancyHeader(translate('link_with_team'));
      setOpenVacancyStep(2);
    }

    if (openVacancyStep === 2) {
      const existingTeamPosition = (teams[0] && teams[0].positions) && teams[0].positions
      .filter((position) => !position.user && !position.jobId)
      .find((position) => position.roleId === vacancyBaseInfo.role.id);

      // show modal to choose an option for the existing team position
      if (existingTeamPosition) {
        setOpenVacancyHeader(translate('existing_team_position_modal_header'));
        setOpenVacancyTeamPositionId(existingTeamPosition.positionId);
        setOpenVacancyStep(3);
      } else {
        setOpenVacancyProcessing(true);
        // change status
        api.put(`recruiting/jobs/${vacancyBaseInfo.id}/status`, { status: VACANCY_STATUSES.OPEN })
        .then(({ ok, status }) => {
          if (ok && status === 200) {
            // link vacancy with a team
            api.patch(`/recruiting/jobs/${vacancyBaseInfo.id}`, {
              role: vacancyBaseInfo.role.id,
              team: teams[0].value,
            })
            .then(({ ok: okInner, status: statusInner }) => {
              if (okInner && statusInner === 200) {
                setTeams([]);
                setOpenVacancyStep(1);
                setLinkWithTeam(false);

                setOpenVacancyProcessing(false);
                setOpenVacancyModalVisible(false);

                const toastHeader = translate('team_vacancy_added_toast_header');
                const toastContent = translate('team_vacancy_added_toast_content', [
                  '{{team}}', (teams && teams[0]) ? teams[0].label : '',
                ]);

                dispatch(showToast(toastHeader, toastContent));
                dispatch(fromActions.getVacancy({ id: vacancyBaseInfo.id, loading: false }));
              }
            });
          } else {
            setOpenVacancyProcessing(false);
          }
        });
      }
    }

    if (openVacancyStep === 3) {
      setOpenVacancyProcessing(true);

      // change status
      api.put(`recruiting/jobs/${vacancyBaseInfo.id}/status`, { status: VACANCY_STATUSES.OPEN })
      .then(({ ok, status }) => {
        if (ok && status === 200) {
          let toastHeader = translate('team_vacancy_added_toast_header');
          let toastContent = translate('team_vacancy_added_toast_content', [
            '{{team}}', (teams && teams[0]) ? teams[0].label : '',
          ]);

          let payload;
          if (openVacancyTeamPositionId && openVacancyStep3Option === 'linkWithTeamPosition') {
            payload = { teamPosition: openVacancyTeamPositionId };
            toastHeader = translate('team_position_updated_toast_header');
            toastContent = translate('team_position_updated_toast_content', [
              '{{team}}', (teams && teams[0]) ? teams[0].label : '',
            ]);
          } else {
            payload = {
              team: teams[0].value,
              role: vacancyBaseInfo.role.id,
            };
          }

          // add team
          api.patch(`/recruiting/jobs/${vacancyBaseInfo.id}`, payload)
          .then(({ ok: okInner, status: statusInner }) => {
            if (okInner && statusInner === 200) {
              setTeams([]);
              setOpenVacancyStep(1);
              setLinkWithTeam(false);

              setOpenVacancyProcessing(false);
              setOpenVacancyModalVisible(false);

              dispatch(showToast(toastHeader, toastContent));
              dispatch(fromActions.getVacancy({ id: vacancyBaseInfo.id, loading: false }));
            }
          });
        } else {
          setOpenVacancyProcessing(false);
        }
      });
    }
  };

  const closeVacancy = (newStatus) => {
    setCloseVacancyProcessing(true);
    // change status first
    api.put(`recruiting/jobs/${vacancyBaseInfo.id}/status`, { status: newStatus })
    .then(({ ok, status }) => {
      setCloseVacancyProcessing(false);

      if (ok && status === 200) {
        // if vacancy is linked with a team
        if (vacancyBaseInfo.team && closeVacancyMode === 'remove_team_position') {
          const teamId = vacancyBaseInfo.team.id;
          const { positionId } = vacancyBaseInfo.teamPosition;
          // Delete linked team position
          api._delete(`teammapping/teams/${teamId}/positions/${positionId}`)
          .then(({ ok: okInner, status: statusInner }) => {
            if (okInner && statusInner === 200) {
              setCloseVacancyModalVisible(false);
              dispatch(fromActions.getVacancy({ id: vacancyBaseInfo.id, loading: false }));
            }
          });
        } else {
          setCloseVacancyProcessing(false);
          setCloseVacancyModalVisible(false);
          dispatch(fromActions.getVacancy({ id: vacancyBaseInfo.id, loading: false }));
        }
      }
    })
    .catch(() => setCloseVacancyProcessing(false));
  };


  const handleStatusOptionClick = (status) => {
    setSelectedStatus(status);

    // status change to staffed/archived
    if (status === VACANCY_STATUSES.STAFFED || status === VACANCY_STATUSES.ARCHIVED) {
      // if vacancy is linked with team and role
      if (vacancyBaseInfo.team && vacancyBaseInfo.teamPosition && vacancyBaseInfo.role) {
        setCloseVacancyModalHeader(status === VACANCY_STATUSES.STAFFED
          ? translate('staff_vacancy')
          : translate('archive_vacancy'));
        setCloseVacancyModalVisible(true);
      } else {
        closeVacancy(status);
      }

      return;
    }

    // status change to open
    if (status === VACANCY_STATUSES.OPEN) {
      if (vacancyBaseInfo.team && vacancyBaseInfo.teamPosition) {
        openVacancy();
      } else {
        setOpenVacancyModalVisible(true);
      }
    }
  };

  // NEW CANDIDATES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ addCandidatesModalVisible, setAddCandidatesModalVisible ] = useState(false);
  useImperativeHandle(ref, () => ({ setAddCandidatesModalVisible }));

  const [ newCandidates, setNewCandidates ] = useState([]);

  const mapSearchResults = useCallback(({ users }) => users.map((user) => ({
    value: user.mail,
    label: getFullName(user),
    subLabel: user.profileData && user.profileData.currentPosition,
    img: api.getUserImageUrl(user.id),
  }
  )), []);

  const [ addNewCandidatesProcessing, setAddNewCandidatesProcessing ] = useState(false);
  const [ addNewCandidatesError, setAddNewCandidatesError ] = useState('');
  const handleAddNewCandidates = () => {
    setAddNewCandidatesProcessing(true);

    api.post(`recruiting/jobs/${vacancyBaseInfo.id}/candidates`, {
      mails: newCandidates.map((newCandidate) => ({ mail: newCandidate.value })),
    })
    .then(({ ok, status, data = {} }) => {
      setAddNewCandidatesProcessing(false);

      if (ok && status === 200) {
        setAddCandidatesModalVisible(false);

        dispatch(fromActions.getVacancyCandidates({ id: vacancyBaseInfo.id }));

        if (history.location.pathname.includes('matching')) {
          dispatch(fromActions.getVacancyMatches({ id: vacancyBaseInfo.id, loading: false }));
        }
      } else {
        setAddNewCandidatesError(data.error?.errorMessage);
      }
    })
    .catch((error) => {
      setAddNewCandidatesProcessing(false);
      setAddNewCandidatesError(error.message);
      console.error(error.message);
    });
  };

  // DELETE VACANCY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ deleteVacancyModalVisible, setDeleteVacancyModalVisible ] = useState(false);
  const [ deleteVacancyProcessing, setDeleteVacancyProcessing ] = useState(false);
  const [ deleteVacancyErrorMessage, setDeleteVacancyErrorMessage ] = useState('');

  const [ deleteVacancyMode, setDeleteVacancyMode ] = useState('remove_vacancy_status');

  // SHARE VACANCY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ shareVacancyModalVisible, setShareVacancyModalVisible ] = useState(false);

  const handleVacancyDelete = async () => {
    setDeleteVacancyProcessing(true);

    try {
      await apiNext.delete(`recruiting/jobs/${vacancyBaseInfo.id}`);

      const toastHeader = translate('vacancy_deleted_toast_header');
      let toastContent = translate('vacancy_deleted_toast_content');

      if (capabilitiesNext.teamsView && vacancyBaseInfo.team) {
        if (deleteVacancyMode === 'remove_team_position') {
          await apiNext
          .delete(`teammapping/teams/${vacancyBaseInfo.team.id}/positions/${vacancyBaseInfo.teamPosition.positionId}`);
          toastContent = translate('vacancy_teampos_deleted_toast_content');
        } else {
          toastContent = translate('vacancy_teampos_status_changed_toast_content');
        }
      }

      dispatch(showToast(toastHeader, toastContent));
      history.push('/vacancies');
    } catch (error) {
      console.error(error.message);
      setDeleteVacancyErrorMessage(error.message);
    } finally {
      setDeleteVacancyProcessing(false);
    }
  };


  // RENDER: ProfileSummary
  return (
    <div className={classNames(styles.profileSummary)}>
      <div className={styles.top}>
        <div className='bluTypeXxs'>{ vacancyBaseInfo.name }</div>
        <div className='bluTypeCopy'>
          { vacancyBaseInfo.metaData && (vacancyBaseInfo.metaData.employmentType || '-') }
        </div>

        { vacancyBaseInfo.team && (
          <Link
            to={`/teams/${vacancyBaseInfo.team.id}/staffing`}
            active={teamClickable}
            openInNewTab
          >
            { vacancyBaseInfo.team.name || vacancyBaseInfo.team }
          </Link>
        ) }

        <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
          { (vacancyBaseInfo.candidates && vacancyBaseInfo.candidates.length > 0)
            ? (
              <span>
                { `${vacancyBaseInfo.candidates.length}\n` }
                { translate('candidates_lbl') }
              </span>
            )
            : translate('vacancy_no_candidates_short') }
        </div>

        <div className={styles.buttons}>
          { /* STATUS BUTTON */ }
          <div
            tabIndex='0'
            role='button'
            className={styles.statusButton}
            onBlur={() => {
              setTimeout(() => {
                setStatusMenuVisible(false);
              }, styles.animationDurationLongMs);
            }}
          >
            <Button
              size='S'
              looks='tertiary'
              disabled={!showControls || noEditing}
              trailingIcon={IconsSvg.ArrowDown}
              onClick={() => setStatusMenuVisible(true)}
            >
              <>
                { translate('vacancy_status_lbl') }
                { ': ' }
                { translate(`vacancy_status_${vacancyBaseInfo.status}`) }
              </>
            </Button>

            <BluCSSTransition
              nodeRef={transitionRef}
              in={statusMenuVisible}
              classNames={{ ...styles }}
            >
              <div className={styles.menu} ref={transitionRef}>
                { statusMenuOptions.map((statusMenu) => {
                  const menuItemProps = {
                    className: classNames(styles.menuItem),
                    onClick: (event) => {
                      handleRipple(event, styles.colorPrimary3);
                      window.setTimeout(() => {
                        handleStatusOptionClick(statusMenu.value);
                        setStatusMenuVisible(false);
                      }, styles.animationDurationLongMs);
                    },
                  };

                  return (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <div key={statusMenu.value} {...menuItemProps}>
                      <span>{ statusMenu.label }</span>
                    </div>
                  );
                }) }
              </div>
            </BluCSSTransition>

          </div>

          { showControls && (
            <div className={styles.flex}>
              <Button
                size='S'
                looks='secondary'
                disabled={vacancyBaseInfo.status !== VACANCY_STATUSES.OPEN || noEditing}
                onClick={() => setAddCandidatesModalVisible(true)}
              >
                { translate('add_candidates_to_vacancy') }

              </Button>

              <OptionsMenu
                withBackground
                flyoutPosition='left'
                options={options}
                onClick={(value) => {
                  switch (value) {
                    case OPTION_TYPES.EDIT:
                      history.push(`/vacancies/${vacancyBaseInfo.id}/edit`);
                      break;
                    case OPTION_TYPES.DUPLICATE:
                      history.push(`/vacancies/${vacancyBaseInfo.id}/duplicate`);
                      break;
                    case OPTION_TYPES.DELETE:
                      setDeleteVacancyModalVisible(true);
                      break;
                    case OPTION_TYPES.SHARE:
                      setShareVacancyModalVisible(true);
                      break;
                    default:
                  }
                }}
              />
            </div>
          ) }

        </div>
      </div>

      <VacancyUsers />

      { /* VACANCY INFO */ }
      <div className={styles.vacancyInfo}>
        <CollapsibleNext
          header={(
            <span className='bluTypeLabel'>
              { translate('vacancy_basic_info') }
            </span>
          )}
          headerClassName={styles.header}
          withBorders
          isOpenOverride={!bp.isXs}
        >
          <>
            { VACANCY_INFO.map((vacancyInfo) => {
              let value = vacancyBaseInfo.metaData?.[vacancyInfo.value] || null;
              if (Array.isArray(value)) {
                value = value.filter(Boolean);
                value = value.length
                  ? <ul>{ value.map((v) => <li key={v}>{ v }</li>) }</ul>
                  : null;
              }
              value = value || '-';

              return (
                <div className={styles.listItem} key={vacancyInfo.value}>
                  <div className={styles.label}>
                    { translate(vacancyInfo.labelKey) }
                  </div>
                  <div className={styles.value}>
                    { value }
                  </div>
                </div>
              );
            }) }
          </>
        </CollapsibleNext>
      </div>


      { /* ADD CANDIDATES MODAL */ }
      { addCandidatesModalVisible && (
        <Modal
          header={translate('add_candidates_to_vacancy_long')}
          secondaryButtonTitle={translate('cancel_lbl')}
          primaryButtonTitle={translate('add_lbl')}
          primaryButtonDisabled={addNewCandidatesProcessing}
          closeOnConfirm={false}
          onClose={() => setAddCandidatesModalVisible(false)}
          onConfirm={handleAddNewCandidates}
        >
          <div className={styles.addCandidatesContent}>
            { addNewCandidatesError && (
              <div className={classNames('error', 'marginTopXs')}>{ addNewCandidatesError }</div>
            ) }

            { translate('add_candidates_to_vacancy_copy') }

            <HotChipsPeopleWithSearch
              placeholder={translate('add_candidates_to_vacancy_placeholder')}
              label={translate('add_candidates_to_vacancy_label')}
              values={newCandidates}
              noResultsHint={translate('add_candidates_to_vacancy_no_results')}
              onUpdate={setNewCandidates}
              canMail={false}
              searchConfig={{
                entrypoint: 'recruiting/candidates/search',
                mapResults: mapSearchResults,
                extraParams: { expand: 'user' },
              }}
            />
          </div>

        </Modal>
      ) }

      { /* DELETE VACANCY MODAL */ }
      { deleteVacancyModalVisible && (
        <Modal
          header={translate('delete_vacancy_title')}
          secondaryButtonTitle={translate('cancel_lbl')}
          redButtonTitle={translate('delete_lbl')}
          redButtonDisabled={deleteVacancyProcessing}
          closeOnConfirm={false}
          onClose={() => {
            setDeleteVacancyErrorMessage('');
            setDeleteVacancyModalVisible(false);
          }}
          onConfirm={handleVacancyDelete}
        >
          <div className={styles.removeVacancyContent}>
            { deleteVacancyErrorMessage && (
              <div className={classNames('error', 'marginBottomXs')}>{ deleteVacancyErrorMessage }</div>
            ) }

            { translate('delete_vacancy_copy') }

            { /* if vacancy is associated with a team */ }
            { (capabilitiesNext.teamsView && vacancyBaseInfo.team) && (
              <>
                <div className={styles.label}>
                  { translate('delete_vacancy_copy2', [ '{{team}}', vacancyBaseInfo.team.name ]) }
                </div>

                <div className={styles.items}>
                  <LabelCheckmark
                    label={translate('delete_vacancy_option1')}
                    showTopBorder
                    active={deleteVacancyMode === 'remove_vacancy_status'}
                    onClick={() => setDeleteVacancyMode('remove_vacancy_status')}
                  />
                  <LabelCheckmark
                    label={translate('delete_vacancy_option2')}
                    active={deleteVacancyMode === 'remove_team_position'}
                    onClick={() => setDeleteVacancyMode('remove_team_position')}
                  />
                </div>
              </>
            ) }
          </div>
        </Modal>
      ) }

      { /* CLOSE VACANCY MODAL (CHANGE STATUS FROM OPEN TO STAFFED/ARCHIVED) */ }
      { closeVacancyModalVisible && (
        <Modal
          header={closeVacancyHeader}
          secondaryButtonTitle={translate('cancel_lbl')}
          primaryButtonTitle={translate('confirm_lbl')}
          primaryButtonDisabled={!closeVacancyMode || closeVacancyProcessing}
          closeOnConfirm={false}
          onClose={() => {
            setCloseVacancyModalVisible(false);
          }}
          onConfirm={() => closeVacancy(selectedStatus)}
        >
          <div className={styles.changeStatusContent}>
            { translate('staff_vacancy_copy') }

            <div>
              <LabelCheckmark
                label={translate('staff_vacancy_option1')}
                showTopBorder
                active={closeVacancyMode === 'remove_vacancy_status'}
                onClick={() => setCloseVacancyMode('remove_vacancy_status')}
              />
              <LabelCheckmark
                label={translate('staff_vacancy_option2')}
                active={closeVacancyMode === 'remove_team_position'}
                onClick={() => setCloseVacancyMode('remove_team_position')}
              />
            </div>
          </div>
        </Modal>
      ) }

      { /* CHANGE TO OPEN STATUS MODAL */ }
      { openVacancyModalVisible && (
        <Modal
          header={openVacancyHeader}
          secondaryButtonTitle={translate('cancel_lbl')}
          primaryButtonTitle={translate('confirm_lbl')}
          primaryButtonDisabled={openVacancyProcessing}
          closeOnConfirm={false}
          onClose={() => {
            setTeams([]);
            setOpenVacancyStep(1);
            setLinkWithTeam(false);
            setOpenVacancyModalVisible(false);
          }}
          onConfirm={openVacancy}
        >
          <div className={styles.changeStatusContent}>
            { /* STEP 1 */ }
            { openVacancyStep === 1 && (
              <>
                { translate('open_vacancy_link_with_team_copy') }

                <div>
                  <LabelCheckmark
                    label={translate('vacancy_link_with_team')}
                    showTopBorder
                    active={!!linkWithTeam}
                    onClick={() => setLinkWithTeam(true)}
                  />
                  <LabelCheckmark
                    label={translate('vacancy_no_link_with_team')}
                    active={!linkWithTeam}
                    onClick={() => setLinkWithTeam(false)}
                  />
                </div>
              </>
            ) }

            { /* STEP 2 */ }
            { (linkWithTeam && openVacancyStep === 2) && (
              <>
                { translate('link_with_team_copy') }

                <HotChipsPeopleWithSearch
                  placeholder={translate('create_vacancy_team_placeholder')}
                  hint={translate('create_vacancy_team_hint')}
                  values={teams}
                  noResultsHint={translate('create_vacancy_team_no_results')}
                  onUpdate={handleTeamsUpdate}
                  canMail={false}
                  searchConfig={{
                    entrypoint: 'core/company/teams',
                    mapResults: mapSearchResultsVacancy,
                    extraParams: {
                      details: 'positions',
                      include: 'positions.jobId',
                    },
                  }}
                />
              </>
            ) }

            { /* STEP 3 */ }
            { openVacancyStep === 3 && (
              <>
                { translate('existing_team_position_modal_copy', [ '{{role}}', vacancyBaseInfo.role.name ]) }

                <div>
                  <LabelCheckmark
                    label={translate('existing_team_position_modal_option1')}
                    showTopBorder
                    active={openVacancyStep3Option === 'linkWithTeamPosition'}
                    onClick={() => setOpenVacancyStep3Option('linkWithTeamPosition')}
                  />
                  <LabelCheckmark
                    label={translate('existing_team_position_modal_option2')}
                    active={openVacancyStep3Option === 'addNewTeamPosition'}
                    onClick={() => setOpenVacancyStep3Option('addNewTeamPosition')}
                  />
                </div>
              </>
            ) }

          </div>

        </Modal>
      ) }

      { shareVacancyModalVisible && (
        <ShareVacancy
          vacancyId={vacancyBaseInfo.id}
          onClose={() => setShareVacancyModalVisible(false)}
        />
      ) }

    </div>
  );
};

export default forwardRef(ProfileSummary);
