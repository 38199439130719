import { get } from 'api';
import * as action from 'store/actions';
import {
  call,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { downloadFile } from 'utils/file';
import { apiNext } from 'apiNext';
import { sleep } from 'utils/delay';

const getParams = (payload) => {
  const { userId, resultId } = payload;
  const params = {};
  if (userId) {
    params.user = userId;
  }
  if (resultId) {
    params.result = resultId;
  }
  return params;
};

function* getAssessmentReport({ payload = {} }) {
  const { id, userId, resultId } = payload;

  try {
    const { status, ok, data = {} } = yield call(
      get,
      `/core/assessments/${id}/report`,
      getParams(payload),
    );
    const { content, report, peers } = data;

    if (ok && status === 200) {
      yield put(action.getAssessmentReportFulfilled({
        id,
        reportStructure: report,
        resultId,
        peers,
      }));
      if (content) {
        if (userId) {
          yield put(action.getResultFromReportFulfilled({
            id,
            data: {
              results: content,
              userId,
              resultId,
              peers,
            },
          }));
        } else {
          yield put(action.getAssessmentResultFulfilled({
            id,
            data: {
              results: content,
              resultId,
              peers,
            },
          }));
        }
      } else {
        yield put(action.getAssessmentResult(id));
      }
      return;
    }
    if (status === 404) {
      yield put(action.getAssessmentResult(id));
      return;
    }
    yield put(action.getAssessmentReportRejected({ id, error: data.error }));
  } catch (error) {
    yield put(action.getAssessmentReportRejected({ id, error }));
  }
}

function* getAssessmentReportPdf({ payload = {} }) {
  const PROCESSING_STATUS = 202;
  const { id } = payload;

  try {
    let responseStatus;
    do {
      const blob = yield call(
        (...args) => apiNext.get(...args),
        `/core/assessments/${id}/report/pdf`,
        getParams(payload),
      );

      const response = blob[apiNext.symRes];
      responseStatus = response?.status;

      if (responseStatus === 200) {
        const fileName = response.headers.get('Content-Disposition')?.split('=').pop();
        downloadFile(blob, `${fileName}.pdf`);
        yield put(action.getAssessmentReportPdfFulfilled(payload));
      }

      if (responseStatus === PROCESSING_STATUS) {
        yield sleep(5000);
      }
    } while (responseStatus === PROCESSING_STATUS);
  } catch (error) {
    yield put(action.getAssessmentReportPdfRejected(payload));
    yield put(action.showToast(
      'generic_error_title',
      'disc_download_report_error',
      true,
    ));
  }
}


export function* watchGetAssessmentReportRequest() {
  yield takeLatest(action.GET_ASSESSMENT_REPORT, getAssessmentReport);
}

export function* watchGetAssessmentReportPdfRequest() {
  yield takeEvery(action.GET_ASSESSMENT_REPORT_PDF, getAssessmentReportPdf);
}
